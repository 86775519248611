import styled, { css } from "styled-components";

export interface AnchorProps {
  kind : string,
  page? : boolean,
  act? : boolean,
  onClick : (e : React.MouseEvent) => void,
  children : React.ReactNode,
};

const ATag = styled.a<AnchorProps>`
  cursor: pointer;

  ${(props) => props.kind === 'unit' && css`
    display: block;
    color: #d2d2d2;
    text-align: center;
    padding: 0.78125vw 0;
    font-size: 1.5625vw;
    font-weight: 600;

    ${props.page && css`
      height: 4.27484vw;
    `}

    ${props.act && css`
      color: #fff;
      background-color: #5493fc;
    `}
  `}

  ${(props) => props.kind === 'track' && css`
    display: block;
    padding: 0.260417vw 0;
    font-size: 1.458333vw;
    color: #d2d2d2;
    text-align: center;

    ${props.page && css`
      height: 4.27484vw;
      line-height: 4.27484vw;
    `}

    ${props.act && css`
      color: #5493fc;
      background-color: #ddeaff;
    `}
  `}

  ${(props) => (props.kind === 'aside' && css`
    display: block;
    height: 100%;
    font-size: .833333vw;
    font-weight: 800;
    line-height: 1.822917vw;
    letter-spacing: -0.026042vw;

    ${props.act && 'background-color: #fdce00;'}
  `)}

  ${(props) => (props.kind === 'asideIn' && css`
    display: block;
    font-size: 0.9375vw;
    text-align: center;
    font-weight: 600;
    line-height: 2.083333vw;

    ${props.act && 'background-color: #fdce00;'}
  `)}

  ${(props) => (props.kind === 'animation' && css`
    display: block;
    font-size: 0.9375vw;
    text-align: center;
    font-weight: 600;
    line-height: 2.083333vw;
    letter-spacing: -0.052083vw;

    ${props.act && 'background-color: #fdce00;'}
  `)}
`;

export const Anchor = ({...props}: AnchorProps) => {
  return (
    <ATag 
      kind={props.kind}
      page={props.page}
      act={props.act}
      onClick={props.onClick}
    >
      {props.children}
    </ATag>
  )
};