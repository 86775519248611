export const StudentBook = [
    {
    "page" : ["6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21","22-23","24-25","26-27","28-29",
            "30-31","32-33","34-35","36-37","38-39","40-41","42-43","44-45","46-47","48-49","50-51","52-53","54-55","56-57","58-59",
            "60-61","62-63","64-65","66-67","68-69","70-71","72-73","74-75","76-77","78-79","80-81","82-83","84-85","86-87","88-89"
            ],
    "unit" : ["6-7","14-15","22-23","30-31",
                "34-35","42-43","50-51","58-59",
                "62-63","70-71","78-79","86-87"
            ],
    "6-7": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)4",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p6-7_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_2",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_1_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "8-9": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)5",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p8-9_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p8-9_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_7",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_6",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_8",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_1_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_1_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "10-11": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)6",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p10-11_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p10-11_answer2-1",
                "posX" : "1600",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p10-11_answer2-2",
                "posX" : "1605",
                "posY" : "575"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_9",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_10",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_1_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "1",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "12-13": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)7",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p12-13_answer1-1",
                "posX" : "680",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p12-13_answer1-2",
                "posX" : "730",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/1_11",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_1_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "14-15": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)8",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p14-15_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_2_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "16-17": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)9",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p16-17_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p16-17_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_2_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_2_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "18-19": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)10",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p18-19_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p18-19_answer2-1",
                "posX" : "1250",
                "posY" : "910"
            },
            {
                "path" : "ebook/studentBook/answer/p18-19_answer2-2",
                "posX" : "1605",
                "posY" : "555"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_2_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "2",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "20-21": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)11",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p20-21_answer1-1",
                "posX" : "940",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p20-21_answer1-2",
                "posX" : "735",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/2_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_2_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "22-23": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)12",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p22-23_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_3_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "24-25": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)13",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p24-25_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p24-25_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_3_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_3_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "26-27": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)14",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p26-27_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p26-27_answer2-1",
                "posX" : "1600",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p26-27_answer2-2",
                "posX" : "1605",
                "posY" : "575"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_3_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "3",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "28-29": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)15",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p28-29_answer1-1",
                "posX" : "680",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p28-29_answer1-2",
                "posX" : "730",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/3_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_3_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "30-31": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)16",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p30-31_answer1-1",
                "posX" : "723",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p30-31_answer1-2",
                "posX" : "715",
                "posY" : "645"
            },
            {
                "path" : "ebook/studentBook/answer/p30-31_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/4_1",
                "posX" : "673",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/4_2",
                "posX" : "665",
                "posY" : "645"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/4_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "32-33": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)17",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p32-33_answer1",
                "posX" : "685",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p32-33_answer2",
                "posX" : "1425",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "34-35": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)18",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p34-35_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_4_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "36-37": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)19",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p36-37_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p36-37_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_4_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_4_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "38-39": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)20",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p38-39_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p38-39_answer2-1",
                "posX" : "1250",
                "posY" : "910"
            },
            {
                "path" : "ebook/studentBook/answer/p38-39_answer2-2",
                "posX" : "1605",
                "posY" : "555"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_4_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "5",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "40-41": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)21",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p40-41_answer1-1",
                "posX" : "940",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p40-41_answer1-2",
                "posX" : "735",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/5_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_4_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "42-43": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)22",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p42-43_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_5_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "44-45": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)23",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p44-45_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p44-45_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_5_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_5_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "46-47": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)24",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p46-47_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p46-47_answer2-1",
                "posX" : "1600",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p46-47_answer2-2",
                "posX" : "1605",
                "posY" : "575"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_5_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "6",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "48-49": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)25",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p48-49_answer1-1",
                "posX" : "680",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p48-49_answer1-2",
                "posX" : "730",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/6_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_5_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "50-51": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)26",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p50-51_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_6_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "52-53": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)27",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p52-53_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p52-53_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_6_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_6_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "54-55": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)28",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p54-55_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p54-55_answer2-1",
                "posX" : "1250",
                "posY" : "910"
            },
            {
                "path" : "ebook/studentBook/answer/p54-55_answer2-2",
                "posX" : "1605",
                "posY" : "555"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_6_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "7",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "56-57": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)29",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p56-57_answer1-1",
                "posX" : "940",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p56-57_answer1-2",
                "posX" : "735",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/7_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_6_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]

    },
    "58-59": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)30",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p58-59_answer1-1",
                "posX" : "723",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p58-59_answer1-2",
                "posX" : "715",
                "posY" : "645"
            },
            {
                "path" : "ebook/studentBook/answer/p58-59_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/8_1",
                "posX" : "673",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/8_2",
                "posX" : "665",
                "posY" : "645"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/8_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "60-61": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)31",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p60-61_answer1",
                "posX" : "685",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p60-61_answer2",
                "posX" : "1425",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "62-63": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)32",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p62-63_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_7_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "64-65": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)33",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p64-65_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p64-65_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_7_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_7_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "66-67": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)34",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p66-67_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p66-67_answer2-1",
                "posX" : "1600",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p66-67_answer2-2",
                "posX" : "1605",
                "posY" : "575"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_7_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "9",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "68-69": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)35",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p68-69_answer1-1",
                "posX" : "680",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p68-69_answer1-2",
                "posX" : "730",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/9_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_7_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "70-71": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)36",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p70-71_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_8_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "72-73": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)37",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p72-73_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p72-73_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_4",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_6",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_5",
                "posX" : "880",
                "posY" : "365"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_7",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_8_2",
                "posX" : "930",
                "posY" : "365"
            },
            {
                "path" : "1_8_3",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "74-75": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)38",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p74-75_answer1",
                "posX" : "755",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p74-75_answer2-1",
                "posX" : "1250",
                "posY" : "910"
            },
            {
                "path" : "ebook/studentBook/answer/p74-75_answer2-2",
                "posX" : "1605",
                "posY" : "555"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_8",
                "posX" : "655",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_9",
                "posX" : "745",
                "posY" : "380"
            }
        ],
        "video" : [
            {
                "path" : "1_8_4",
                "posX" : "795",
                "posY" : "380"
            }
        ],
        "card" : [
            {
                "path" : "10",
                "posX" : "705",
                "posY" : "945"
            }
        ]
    },
    "76-77": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)39",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p76-77_answer1-1",
                "posX" : "940",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p76-77_answer1-2",
                "posX" : "735",
                "posY" : "550"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/10_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_8_5",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "78-79": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)40",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p78-79_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_2",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_3",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_9_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "80-81": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)41",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p80-81_answer1",
                "posX" : "980",
                "posY" : "365"
            },
            {
                "path" : "ebook/studentBook/answer/p80-81_answer2-1",
                "posX" : "1350",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p80-81_answer2-2",
                "posX" : "1355",
                "posY" : "405"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_4",
                "posX" : "765",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_5",
                "posX" : "880",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "1_9_2",
                "posX" : "930",
                "posY" : "365"
            }
        ]
    },
    "82-83": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)42",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p82-83_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p82-83_answer2-1",
                "posX" : "1600",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p82-83_answer2-2",
                "posX" : "1605",
                "posY" : "460"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_6",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "card" : [
            {
                "path" : "11",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_7",
                "posX" : "745",
                "posY" : "515"
            }
        ],
        "video" : [
            {
                "path" : "1_9_3",
                "posX" : "795",
                "posY" : "515"
            }
        ]
    },
    "84-85": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)43",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p84-85_answer1-1",
                "posX" : "680",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p84-85_answer1-2",
                "posX" : "735",
                "posY" : "625"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/11_8",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "1_9_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "86-87": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)44",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p86-87_answer1-1",
                "posX" : "723",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p86-87_answer1-2",
                "posX" : "715",
                "posY" : "645"
            },
            {
                "path" : "ebook/studentBook/answer/p86-87_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book1/flashCard/mp3/unit_01/12_1",
                "posX" : "673",
                "posY" : "850"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/12_2",
                "posX" : "665",
                "posY" : "645"
            },
            {
                "path" : "/book1/flashCard/mp3/unit_01/12_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "88-89": {
        "path" : "ebook/studentBook/book/Phonics_Hero_1_(SB)45",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p88-89_answer1",
                "posX" : "685",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p88-89_answer2",
                "posX" : "1425",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    }
  },
  {
    "page" : ["6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21","22-23","24-25","26-27","28-29",
    "30-31","32-33","34-35","36-37","38-39","40-41","42-43","44-45","46-47","48-49","50-51","52-53","54-55","56-57","58-59",
    "60-61","62-63","64-65","66-67","68-69","70-71","72-73","74-75","76-77","78-79","80-81","82-83","84-85","86-87","88-89"],
    "unit" : ["6-7","14-15","22-23","30-31",
        "34-35","42-43","50-51","58-59",
        "62-63","70-71","78-79","86-87"],
    "6-7": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)4",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p6-7_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_2",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_3",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_4",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_5",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_1_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "8-9": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)5",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p8-9_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p8-9_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_6",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_8",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_7",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_1_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "10-11": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)6",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p10-11_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p10-11_answer2",
                "posX" : "1350",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "1",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_9",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_1_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "12-13": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)7",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p12-13_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/1_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_1_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "14-15": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)8",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p14-15_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_2_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "16-17": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)9",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p16-17_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p16-17_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_2_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "18-19": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)10",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p18-19_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p18-19_answer2",
                "posX" : "1365",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "2",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_2_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "20-21": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)11",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p20-21_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/2_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_2_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "22-23": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)12",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p22-23_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_3_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "24-25": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)13",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p24-25_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p24-25_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_3_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "26-27": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)14",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p26-27_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p26-27_answer2",
                "posX" : "1350",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "3",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_3_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "28-29": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)15",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p28-29_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/3_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_3_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "30-31": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)16",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p30-31_answer1-1",
                "posX" : "720",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p30-31_answer1-2",
                "posX" : "715",
                "posY" : "590"
            },
            {
                "path" : "ebook/studentBook/answer/p30-31_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/4_1",
                "posX" : "670",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/4_2",
                "posX" : "665",
                "posY" : "590"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/4_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "32-33": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)17",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p32-33_answer1",
                "posX" : "735",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p32-33_answer2",
                "posX" : "1423",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "34-35": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)18",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p34-35_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_4_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "36-37": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)19",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p36-37_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p36-37_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_4_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "38-39": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)20",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p38-39_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p38-39_answer2",
                "posX" : "1365",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "5",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_4_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "40-41": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)21",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p40-41_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/5_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_4_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "42-43": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)22",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p42-43_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_5_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "44-45": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)23",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p44-45_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p44-45_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_5_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "46-47": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)24",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p46-47_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p46-47_answer2",
                "posX" : "1350",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "6",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_5_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "48-49": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)25",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p48-49_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/6_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_5_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "50-51": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)26",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p50-51_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_6_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "52-53": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)27",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p52-53_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p52-53_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_6_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "54-55": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)28",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p54-55_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p54-55_answer2",
                "posX" : "1365",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "7",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_6_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "56-57": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)29",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p56-57_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/7_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_6_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "58-59": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)30",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p58-59_answer1-1",
                "posX" : "720",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p58-59_answer1-2",
                "posX" : "715",
                "posY" : "590"
            },
            {
                "path" : "ebook/studentBook/answer/p58-59_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/8_1",
                "posX" : "670",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/8_2",
                "posX" : "665",
                "posY" : "590"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/8_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "60-61": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)31",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p60-61_answer1",
                "posX" : "735",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p60-61_answer2",
                "posX" : "1423",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "62-63": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)32",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p62-63_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_7_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "64-65": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)33",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p64-65_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p64-65_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_7_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "66-67": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)34",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p66-67_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p66-67_answer2",
                "posX" : "1350",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "9",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_7_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "68-69": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)35",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p68-69_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/9_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_7_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "70-71": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)36",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p70-71_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_8_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "72-73": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)37",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p72-73_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p72-73_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_8_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "74-75": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)38",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p74-75_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p74-75_answer2",
                "posX" : "1365",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "10",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_8_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "76-77": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)39",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p76-77_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/10_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_8_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "78-79": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)40",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p78-79_answer2",
                "posX" : "1680",
                "posY" : "365"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_4",
                "posX" : "1580",
                "posY" : "365"
            }
        ],
        "video" : [
            {
                "path" : "2_9_1",
                "posX" : "1630",
                "posY" : "365"
            }
        ]
    },
    "80-81": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)41",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p80-81_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p80-81_answer2",
                "posX" : "1480",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_7",
                "posX" : "1430",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "2_9_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "82-83": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)42",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p82-83_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p82-83_answer2",
                "posX" : "1350",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "11",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "2_9_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "84-85": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)43",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p84-85_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/11_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "2_9_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "86-87": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)44",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p86-87_answer1-1",
                "posX" : "720",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p86-87_answer1-2",
                "posX" : "715",
                "posY" : "590"
            },
            {
                "path" : "ebook/studentBook/answer/p86-87_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book2/flashCard/mp3/unit_01/12_1",
                "posX" : "670",
                "posY" : "850"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/12_2",
                "posX" : "665",
                "posY" : "590"
            },
            {
                "path" : "/book2/flashCard/mp3/unit_01/12_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "88-89": {
        "path" : "ebook/studentBook/book/Phonics_Hero_2_(SB)45",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p88-89_answer1",
                "posX" : "735",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p88-89_answer2",
                "posX" : "1423",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    }
  },
  {
    "page" : [
      "6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21","22-23","24-25","26-27","28-29","30-31","32-33","34-35",
      "36-37","38-39","40-41","42-43","44-45","46-47","48-49","50-51","52-53","54-55","56-57","58-59","60-61","62-63","64-65",
      "66-67","68-69","70-71","72-73","74-75","76-77","78-79","80-81","82-83","84-85","86-87","88-89","90-91","92-93"],
    "unit" : ["6-7","14-15","22-23","30-31","38-39","46-47",
        "50-51","58-59","66-67","74-75","82-83","90-91"],
    "6-7": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)4",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p6-7_answer2",
                "posX" : "1680",
                "posY" : "350"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_2",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_3",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_4",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_5",
                "posX" : "1580",
                "posY" : "350"
            }
        ],
        "video" : [
            {
                "path" : "3_1_1",
                "posX" : "1630",
                "posY" : "350"
            }
        ]
    },
    "8-9": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)5",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p8-9_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p8-9_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_6",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_8",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_7",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_1_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "10-11": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)6",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p10-11_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p10-11_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "1",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_9",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_1_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "12-13": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)7",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p12-13_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/1_10",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_1_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "14-15": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)8",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p14-15_answer2",
                "posX" : "1680",
                "posY" : "350"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_4",
                "posX" : "1580",
                "posY" : "350"
            }
        ],
        "video" : [
            {
                "path" : "3_2_1",
                "posX" : "1630",
                "posY" : "350"
            }
        ]
    },
    "16-17": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)9",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p16-17_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p16-17_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_2_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "18-19": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)10",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p18-19_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p18-19_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "2",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_2_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "20-21": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)11",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p20-21_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/2_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_2_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "22-23": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)12",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p22-23_answer2",
                "posX" : "1680",
                "posY" : "350"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_4",
                "posX" : "1580",
                "posY" : "350"
            }
        ],
        "video" : [
            {
                "path" : "3_3_1",
                "posX" : "1630",
                "posY" : "350"
            }
        ]
    },
    "24-25": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)13",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p24-25_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p24-25_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_3_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "26-27": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)14",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p26-27_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p26-27_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "3",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_3_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "28-29": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)15",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p28-29_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/3_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_3_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "30-31": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)16",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p30-31_answer2",
                "posX" : "1680",
                "posY" : "350"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_4",
                "posX" : "1580",
                "posY" : "350"
            }
        ],
        "video" : [
            {
                "path" : "3_4_1",
                "posX" : "1630",
                "posY" : "350"
            }
        ]
    },
    "32-33": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)17",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p32-33_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p32-33_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_4_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "34-35": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)18",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p34-35_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p34-35_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "4",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_4_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "36-37": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)19",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p36-37_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/4_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_4_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "38-39": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)20",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p38-39_answer2",
                "posX" : "1680",
                "posY" : "350"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_2",
                "posX" : "765",
                "posY" : "525"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_4",
                "posX" : "1580",
                "posY" : "350"
            }
        ],
        "video" : [
            {
                "path" : "3_5_1",
                "posX" : "1630",
                "posY" : "350"
            }
        ]
    },
    "40-41": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)21",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p40-41_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p40-41_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_5_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "42-43": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)22",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p42-43_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p42-43_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "5",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_5_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "44-45": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)23",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p44-45_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/5_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_5_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]

    },
    "46-47": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)24",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p46-47_answer1-1",
                "posX" : "720",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p46-47_answer1-2",
                "posX" : "715",
                "posY" : "590"
            },
            {
                "path" : "ebook/studentBook/answer/p46-47_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/6_1",
                "posX" : "670",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/6_2",
                "posX" : "665",
                "posY" : "590"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/6_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "48-49": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)25",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p48-49_answer1",
                "posX" : "797",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p48-49_answer2",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "50-51": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)26",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p50-51_answer2",
                "posX" : "1680",
                "posY" : "465"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_2",
                "posX" : "765",
                "posY" : "580"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_4",
                "posX" : "1580",
                "posY" : "465"
            }
        ],
        "video" : [
            {
                "path" : "3_6_1",
                "posX" : "1630",
                "posY" : "465"
            }
        ]
    },
    "52-53": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)27",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p52-53_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p52-53_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_6_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "54-55": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)28",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p54-55_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p54-55_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "7",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_6_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "56-57": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)29",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p56-57_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/7_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_6_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "58-59": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)30",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p58-59_answer2",
                "posX" : "1680",
                "posY" : "465"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_2",
                "posX" : "765",
                "posY" : "580"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_4",
                "posX" : "1580",
                "posY" : "465"
            }
        ],
        "video" : [
            {
                "path" : "3_7_1",
                "posX" : "1630",
                "posY" : "465"
            }
        ]
    },
    "60-61": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)31",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p60-61_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p60-61_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_7_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "62-63": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)32",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p62-63_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p62-63_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "8",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_7_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "64-65": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)33",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p64-65_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/8_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_7_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "66-67": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)34",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p66-67_answer2",
                "posX" : "1680",
                "posY" : "465"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_2",
                "posX" : "765",
                "posY" : "580"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_4",
                "posX" : "1580",
                "posY" : "465"
            }
        ],
        "video" : [
            {
                "path" : "3_8_1",
                "posX" : "1630",
                "posY" : "465"
            }
        ]
    },
    "68-69": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)35",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p68-69_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p68-69_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_8_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "70-71": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)36",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p70-71_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p70-71_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "9",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_8_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "72-73": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)37",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p72-73_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/9_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_8_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "74-75": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)38",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p74-75_answer2",
                "posX" : "1680",
                "posY" : "465"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_2",
                "posX" : "765",
                "posY" : "580"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_4",
                "posX" : "1580",
                "posY" : "465"
            }
        ],
        "video" : [
            {
                "path" : "3_9_1",
                "posX" : "1630",
                "posY" : "465"
            }
        ]
    },
    "76-77": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)39",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p76-77_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p76-77_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_9_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "78-79": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)40",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p78-79_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p78-79_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "10",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_9_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "80-81": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)41",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p80-81_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/10_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_9_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "82-83": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)42",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p82-83_answer2",
                "posX" : "1680",
                "posY" : "465"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_1",
                "posX" : "680",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_2",
                "posX" : "765",
                "posY" : "580"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_3",
                "posX" : "1465",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_4",
                "posX" : "1580",
                "posY" : "465"
            }
        ],
        "video" : [
            {
                "path" : "3_10_1",
                "posX" : "1630",
                "posY" : "465"
            }
        ]
    },
    "84-85": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)43",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p84-85_answer1",
                "posX" : "980",
                "posY" : "470"
            },
            {
                "path" : "ebook/studentBook/answer/p84-85_answer2",
                "posX" : "1500",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_5",
                "posX" : "765",
                "posY" : "945"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_7",
                "posX" : "1450",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_6",
                "posX" : "880",
                "posY" : "470"
            }
        ],
        "video" : [
            {
                "path" : "3_10_2",
                "posX" : "930",
                "posY" : "470"
            }
        ]
    },
    "86-87": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)44",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p86-87_answer1",
                "posX" : "760",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p86-87_answer2",
                "posX" : "1370",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [
            {
                "path" : "11",
                "posX" : "710",
                "posY" : "945"
            }
        ],
        "chant" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_8",
                "posX" : "660",
                "posY" : "945"
            }
        ],
        "video" : [
            {
                "path" : "3_10_3",
                "posX" : "810",
                "posY" : "945"
            }
        ]
    },
    "88-89": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)45",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p88-89_answer1",
                "posX" : "760",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/11_9",
                "posX" : "1435",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : [
            {
                "path" : "3_10_4",
                "posX" : "1485",
                "posY" : "945"
            }
        ]
    },
    "90-91": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)46",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p90-91_answer1-1",
                "posX" : "720",
                "posY" : "850"
            },
            {
                "path" : "ebook/studentBook/answer/p90-91_answer1-2",
                "posX" : "715",
                "posY" : "590"
            },
            {
                "path" : "ebook/studentBook/answer/p90-91_answer2",
                "posX" : "1510",
                "posY" : "945"
            }
        ],
        "track" : [
            {
                "path" : "/book3/flashCard/mp3/unit_01/12_1",
                "posX" : "670",
                "posY" : "850"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/12_2",
                "posX" : "665",
                "posY" : "590"
            },
            {
                "path" : "/book3/flashCard/mp3/unit_01/12_3",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "card" : [],
        "chant" : [],
        "video" : []
    },
    "92-93": {
        "path" : "ebook/studentBook/book/Phonics_Hero_3_(SB)47",
        "answer" : [
            {
                "path" : "ebook/studentBook/answer/p92-93_answer1",
                "posX" : "797",
                "posY" : "945"
            },
            {
                "path" : "ebook/studentBook/answer/p92-93_answer2",
                "posX" : "1460",
                "posY" : "945"
            }
        ],
        "track" : [],
        "card" : [],
        "chant" : [],
        "video" : []
    }
    }
];