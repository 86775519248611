import { MenuTemplate } from "../../_templates/MenuTemplate/menuTemplate";

export interface MenuProps {
  
};

export const Menu = ({...props}: MenuProps) => {
  return (
    <MenuTemplate />
  )
};