import { useEffect, useRef } from "react";
import H5AudioPlayer from "react-h5-audio-player";
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from "styled-components";
import { RootState } from "../../../store";
import { setAudioCurrentRange, setAudioCurrentTime, setAudioDurationTime, setAudioPlay, setRepeat, setRewind } from "../../../store/AudioSlice";
import { Button } from "../../_atoms/Button/button";
import { ProgressBar } from "../ProgressBar/progressBar";
import { ProgressTime } from "../ProgressTime/progressTime";

export interface AudioPlayProgressProps {
  page : boolean,
};

const ProgressBarWrap = styled.div<AudioPlayProgressProps>`
  background: center /contain no-repeat;
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 48.489583vw;
  height: 5.104167vw;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_progress_bg.png);

  ${(props) => props.page && css`
    width: 69.270833vw;
    height: 7.291667vw;
  `}
`;

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const AudioPlayProgress = ({...props}: AudioPlayProgressProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const player = useRef<H5AudioPlayer>(null);

  useEffect(() => {
    if (player.current instanceof H5AudioPlayer) {

      // mp3 렌더링시 자동재생 되는 것 막기
      player.current.audio.current?.pause();
  
      // 재생 / 일시정지
      audio.play ? player.current.audio.current?.play() : player.current.audio.current?.pause();
  
      if (player.current.audio.current) {
        player.current.audio.current.loop = audio.repeat ? true : false;
      }
    }
  }, [audio.current_unit, audio.current_track, audio.play, audio.repeat]);

  useEffect(() => {
    player.current && player.current.audio.current?.addEventListener('timeupdate', onTimeUpdate);
  });

  const onTimeUpdate = () => {
    if (player.current instanceof H5AudioPlayer) {
      player.current.audio.current && dispatch(setAudioCurrentTime(player.current.audio.current.currentTime));

      // progress bar
      player.current.audio.current && dispatch(setAudioCurrentRange((100 * player.current.audio.current.currentTime) / player.current.audio.current.duration));
    }
  };

  const onTrackPlayEnd = () => {
    dispatch(setAudioPlay(false));
    dispatch(setRewind(true));
    dispatch(setAudioCurrentTime(0));

    // progress bar
    dispatch(setAudioCurrentRange(0));
  };

  return (
    <ProgressBarWrap page={props.page}>
      <AudioPlayer
        ref={player}
        src={'https://content-cdn.chungchy.com/Phonics_Hero/book' + book.current_book + '/flashCard/mp3/unit_01/' + (audio.current_unit + 1) + '_' + (audio.current_track + 1) + '.mp3'}
        autoPlayAfterSrcChange={audio.play}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        onEnded={onTrackPlayEnd}
        onLoadedMetaData={() => {
          dispatch(setAudioDurationTime(player.current?.audio.current?.duration));
        }}
      />
      <Button kind="repeat" act={audio.repeat} onClick={() => dispatch(setRepeat(!audio.repeat))} />
      <ProgressBar kind="audio" page={props.page} player={player.current}/>
      <ProgressTime kind="audio" page={props.page} />
    </ProgressBarWrap>
  )
};