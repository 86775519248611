import styled, { css } from "styled-components";

export interface ListProps {
  kind : string,
  order : boolean,
  page? : boolean,
  act? : boolean,
  top? : number,
  className? : string,
  children : React.ReactNode,
};

const UlTag = styled.ul<ListProps>`
  ${(props) => props.kind === 'unit' && css`
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    overflow-y: auto;
    left: 0;
    background-color: #fff;

    ${props.page && css`
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      z-index: 1;
      box-shadow: -0.260417vw 0 0.520833vw 0;
    `}
  `}

  ${(props) => props.kind === 'track' && css`
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: #f9f9f9;
    overflow-y: auto;
  `}

  ${(props) => props.kind === 'aside' && css`
    padding: 0.520833vw 0 0;
    margin-bottom: 0.78125vw;
    background-color: #fff;
    text-align: center;
  `};

  ${(props) => (props.kind === 'asideIn' && css`
    display : ${props.act ? 'flex' : 'none'};
    flex-direction: column;
    grid-row-gap: 0.260417vw;
    position: absolute;
    top: 0;
    left: calc(100% + 1.041667vw);
    width: 11.375vw;
    max-height: 15.625vw;
    padding: 0.78125vw;
    background-color: #f2f2f2;
    overflow-y: scroll;
    filter: drop-shadow(0 0 0.104167vw rgba(0, 0, 0, 0.15));
  `)}

  ${(props) => (props.kind === 'animation' && css`
    display: ${props.act ? 'flex' : 'none'};
    flex-direction: column;
    grid-row-gap: 0.260417vw;
    position: absolute;
    width: 11.375vw;
    max-height: 15.625vw;
    padding: 0.78125vw;
    filter: drop-shadow(0 0 0.104167vw rgba(0, 0, 0, 0.15));
    background-color: #f2f2f2;

    left: calc(100% + 12.674vw);
    overflow-y: auto;

    top : ${props.top ? props.top : '0'}px;
  `)}

  ${(props) => props.kind === 'dots' && css`
    display: flex;
    grid-column-gap: 0.520833vw;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 1.5625vw;
    position: absolute;
    bottom : 0.35vw;
    left : 50%;
    transform: translateX(-50%);
  `}

  ${(props) => props.kind === 'card' && css`
    display: flex;
    grid-column-gap: 1.5625vw;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

export const List = ({...props}: ListProps) => {
  return (
    props.order ? <ol>{props.children}</ol> : 
      <UlTag
        kind={props.kind}
        order={props.order}
        page={props.page}
        act={props.act}
        top={props.top}
        className={props.className}
      >
        {props.children}
      </UlTag>
  )
};