import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from "../../../store";
import { setFullscreen } from "../../../store/CommonSlice";
import { setVideoPlay } from "../../../store/VideoSlice";
import { Button } from "../../_atoms/Button/button";
import { Video } from "../../_atoms/Video/video";
import { ProgressBar } from "../../_molecules/ProgressBar/progressBar";
import { ProgressTime } from "../../_molecules/ProgressTime/progressTime";

export interface VideoWrapProps {
  
};

const ProgressBarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 4.270833vw;
  background-color: rgb(85, 85, 85);
`;

export const VideoWrap = ({...props}: VideoWrapProps) => {
  const video = useSelector((state : RootState) => state.video);
  const dispatch = useDispatch();
  
  return (
    <>
      <Video />
      <ProgressBarWrap>
        <Button kind="play" act={video.play ? true : false} onClick={() => dispatch(setVideoPlay(!video.play))}/>
        <ProgressBar kind="video" page={false} />
        <ProgressTime kind="video" page={false} />
        <Button kind="fullscreen" onClick={() => dispatch(setFullscreen(true))}/>
      </ProgressBarWrap>
    </>
  )
};