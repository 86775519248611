export const Readers = [
    {
        "page" : ["4-5","6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21","22-23","24-25","26-27","28-29"],
        "unit" : ["4-5", "12-13", "20-21"],
        "4-5": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_13",
            "track" : [
                {
                    "path" : "/book1/flashCard/mp3/unit_01/13_1",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [],
            "video" : [
                {
                    "path" : "1_R_1",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "6-7": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_14",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/6p",
                    "posX" : "852",
                    "posY" : "300"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/7p",
                    "posX" : "1557",
                    "posY" : "300"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "8-9": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_15",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/8p",
                    "posX" : "882",
                    "posY" : "300"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/9p",
                    "posX" : "1600",
                    "posY" : "300"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "10-11": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_16",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/10p",
                    "posX" : "880",
                    "posY" : "300"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory1(Bear'sBirthday)/11p",
                    "posX" : "1575",
                    "posY" : "369"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "12-13": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_17",
            "track" : [
                {
                    "path" : "/book1/flashCard/mp3/unit_01/13_2",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p12-13_answer1_(readers1)",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : [
                {
                    "path" : "1_R_2",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "14-15": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_18",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/14p",
                    "posX" : "1007",
                    "posY" : "340"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/15p",
                    "posX" : "1707",
                    "posY" : "340"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "16-17": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_19",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/16p",
                    "posX" : "1007",
                    "posY" : "340"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/17p",
                    "posX" : "1707",
                    "posY" : "340"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "18-19": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_110",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/18p",
                    "posX" : "1007",
                    "posY" : "340"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory2(TheKeyandtheNotebook)/19p",
                    "posX" : "1707",
                    "posY" : "340"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "20-21": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_111",
            "track" : [
                {
                    "path" : "/book1/flashCard/mp3/unit_01/13_3",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p20-21_answer1_(readers1)",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : [
                {
                    "path" : "1_R_3",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "22-23": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_112",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/22p",
                    "posX" : "884",
                    "posY" : "369"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/23p",
                    "posX" : "1490",
                    "posY" : "369"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "24-25": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_113",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/24p",
                    "posX" : "820",
                    "posY" : "369"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/25p",
                    "posX" : "1519",
                    "posY" : "369"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "26-27": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_114",
            "track" : [
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/26p",
                    "posX" : "873",
                    "posY" : "369"
                },
                {
                    "path" : "/book1/ebook/readers/mp3/readers1_Strory3(HideandSeek)/27p",
                    "posX" : "1626",
                    "posY" : "369"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "28-29": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_115",
            "track" : [],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p28_answer1_(readers1)",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : []
        }
    },
    {
        "page" : ["4-5","6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21","22-23","24-25","26-27","28-29"],
        "unit" : ["4-5", "12-13", "20-21"],
        "4-5": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_23",
            "track" : [
                {
                    "path" : "/book2/flashCard/mp3/unit_01/13_1",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [],
            "video" : [
                {
                    "path" : "2_R_1",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "6-7": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_24",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/6p",
                    "posX" : "890",
                    "posY" : "341"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/7p",
                    "posX" : "1625",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "8-9": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_25",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/8p",
                    "posX" : "924",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/9p",
                    "posX" : "1625",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "10-11": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_26",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/10p",
                    "posX" : "924",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory1(TedWantstoPlay)/11p",
                    "posX" : "1625",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "12-13": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_27",
            "track" : [
                {
                    "path" : "/book2/flashCard/mp3/unit_01/13_2",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p12-13_answer1",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : [
                {
                    "path" : "2_R_2",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "14-15": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_28",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/14p",
                    "posX" : "905",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/15p",
                    "posX" : "1583",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "16-17": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_29",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/16p",
                    "posX" : "905",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/17p",
                    "posX" : "1557",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "18-19": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_210",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/18p",
                    "posX" : "871",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory2(FindthePop)/19p",
                    "posX" : "1644",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "20-21": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_211",
            "track" : [
                {
                    "path" : "/book2/flashCard/mp3/unit_01/13_3",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p20-21_answer1",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : [
                {
                    "path" : "2_R_3",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "22-23": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_212",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/22p",
                    "posX" : "936",
                    "posY" : "341"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/23p",
                    "posX" : "1623",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "24-25": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_213",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/24p",
                    "posX" : "933",
                    "posY" : "388"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/25p",
                    "posX" : "1630",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "26-27": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_214",
            "track" : [
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/26p",
                    "posX" : "941",
                    "posY" : "387"
                },
                {
                    "path" : "/book2/ebook/readers/mp3/readers2_Strory3(TheFiveCubes)/27p",
                    "posX" : "1670",
                    "posY" : "387"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "28-29": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_215",
            "track" : [],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p28_answer1",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : []
        }
    },
    {
        "page" : ["4-5","6-7","8-9","10-11","12-13","14-15","16-17","18-19","20-21"],
        "unit" : ["4-5", "12-13"],
        "4-5": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_33",
            "track" : [
                {
                    "path" : "/book3/flashCard/mp3/unit_01/13_1",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [],
            "video" : [
                {
                    "path" : "3_R_1",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "6-7": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_34",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/6p",
                    "posX" : "924",
                    "posY" : "388"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/7p",
                    "posX" : "1651",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "8-9": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_35",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/8p",
                    "posX" : "926",
                    "posY" : "388"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/9p",
                    "posX" : "1653",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "10-11": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_36",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/10p",
                    "posX" : "930",
                    "posY" : "387"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory1(ABusyDay)/11p",
                    "posX" : "1606",
                    "posY" : "387"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "12-13": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_37",
            "track" : [
                {
                    "path" : "/book3/flashCard/mp3/unit_01/13_2",
                    "posX" : "1570",
                    "posY" : "366"
                }
            ],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p12-13_answer1",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : [
                {
                    "path" : "3_R_2",
                    "posX" : "1620",
                    "posY" : "366"
                }
            ]
        },
        "14-15": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_38",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/14p",
                    "posX" : "869",
                    "posY" : "345"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/15p",
                    "posX" : "1578",
                    "posY" : "345"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "16-17": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_39",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/16p",
                    "posX" : "911",
                    "posY" : "346"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/17p",
                    "posX" : "1610",
                    "posY" : "346"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "18-19": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_310",
            "track" : [
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/18p",
                    "posX" : "925",
                    "posY" : "345"
                },
                {
                    "path" : "/book3/ebook/readers/mp3/readers3_Strory2(PlayingintheRain)/19p",
                    "posX" : "1638",
                    "posY" : "388"
                }
            ],
            "answer" : [],
            "video" : []
        },
        "20-21": {
            "path" : "ebook/readers/book/Readers-Phonics_Hero_311",
            "track" : [],
            "answer" : [
                {
                    "path" : "ebook/readers/answer/p20_answer1",
                    "posX" : "685",
                    "posY" : "880"
                }
            ],
            "video" : []
        }
    }
];