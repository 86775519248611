import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store";
import { Svg } from "../../_atoms/Svg/svg";

export interface MaskProps {
  
};

const MaskWrap = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
`;

export const Mask = ({...props}: MaskProps) => {
  const tool = useSelector((state : RootState) => state.tool);

  return (
    <MaskWrap>
      <Svg />
    </MaskWrap>
  )
};