import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioTrack } from '../../../asset/utils/AudioTrack';
import { RootState } from "../../../store";
import { setCurrentTrack, setCurrentUnit } from '../../../store/AudioSlice';
import { Anchor } from "../../_atoms/Anchor/anchor";
import { Item } from "../../_atoms/Item/item";
import { List } from "../../_atoms/List/list";

export interface AudioListProps {
  kind : string,
  page : boolean,
};

export const AudioList = ({...props}: AudioListProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const [list, setList] = useState<number[][] | number[]>(AudioTrack[book.current_book - 1]);

  useEffect(() => {
    if (props.kind) {
      props.kind === 'unit' ? setList(AudioTrack[book.current_book - 1]) : setList(AudioTrack[book.current_book - 1][audio.current_unit]);
    }
  }, [audio.current_unit]);

  const setAudioList = (idx : number) : void => {
    if (props.kind === "unit") {
      dispatch(setCurrentUnit(idx));
      dispatch(setCurrentTrack(0));
    } else if (props.kind === 'track') {
      dispatch(setCurrentTrack(idx));
    }
  };

  return (
    <List kind={props.kind} order={false} page={props.page}>
      {list.map((item, idx) => {
        return (
          <Item 
            kind={props.kind}
            page={props.page}
            idx={idx}
          >
            <Anchor
              kind={props.kind}
              page={props.page}
              act={props.kind === "unit" ? (audio.current_unit === idx ? true : false) : (audio.current_track === idx ? true : false)}
              onClick={() => setAudioList(idx)}
            >
              {props.kind === "unit" ? (idx === list.length - 1 ? 'Readers' : 'Unit' + (idx + 1 < 10 ? '0' + (idx + 1) : (idx + 1))) : ('Track' + (item as number < 10 ? '0' + item : item))}
            </Anchor>
          </Item>
        )
      })}
    </List>
  )
};