import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dice_act : false,
  dice : 1, 
};

const game = createSlice({
  name : "game",
  initialState,
  reducers : {
    resetGame : () => initialState,
    setDice(state, action) {
      state.dice = action.payload;
    },
    setDiceAct(state, action) {
      state.dice_act = action.payload;
    }
  }
});

export const { resetGame, setDice, setDiceAct } = game.actions;
 
export default game;