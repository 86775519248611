import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { RootState } from '../../../store';
import { resetAudio } from '../../../store/AudioSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { AudioPlayButton } from "../../_molecules/AudioPlayButton/audioPlayButton";
import { AudioPlayProgress } from "../../_molecules/AudioPlayProgress/audioPlayProgress";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";

export interface AudioPlayerWrapProps {
  page : boolean,
  act? : boolean,
  rewind? : boolean, 
};

interface CdProps extends AudioPlayerWrapProps {
  book : number
};

const Cd = styled.div<CdProps>`
  background: center /contain no-repeat;
  align-self: center;
  justify-self: center;
  transform-origin: 50% 50%;
  width: 21.875vw;
  height: 21.875vw;
  filter: drop-shadow(0 0 0.104167vw rgba(0, 0, 0, 0.20));
  animation: rotate 5s linear infinite;
  animation-play-state: paused;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_cd${(props) => props.book}.png);

  ${(props) => props.page && css`
    align-self: flex-end;
    width: 31.25vw;
    height: 31.25vw;
    filter: drop-shadow(0 0.104167vw 0.15625vw rgba(0, 0, 0, 0.4));
  `}

  ${(props) => props.act && 'animation-play-state: running;'}

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  } 

  ${(props) => props.rewind && css`
    animation: rewind 0.5s linear 1;
    animation-play-state: running;
  `
  }

  @keyframes rewind {
    100% {
      transform: rotate(-360deg);
    }
  }
`;

const ProgressWrap = styled.div<AudioPlayerWrapProps>`
  background: center /contain no-repeat;
  background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_progress_bg.png) center center / contain no-repeat;
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: ${(props) => props.page ? 69.270833 : 48.489583}vw;
  height: ${(props) => props.page ? 7.291667 : 5.104167}vw;
`;

export const AudioPlayerWrap = ({...props}: AudioPlayerWrapProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <>
      {props.page && (
        <>
          <ImageButton 
            kind="home"
            src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_home_150px.png"
            alt="home"
            onClick={() => {
              dispatch(setBtnSound(true));
              dispatch(resetAudio());
              navigate("/");
            }}
          />
          <ImageButton 
            kind="back"
            src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_pre_150px.png"
            alt="back"
            onClick={() => {
              dispatch(setBtnSound(true));
              dispatch(resetAudio());
              navigate("/menu");
            }}
          />
        </>
      )}

      <Cd page={props.page} book={book.current_book} act={audio.play} rewind={audio.rewind}/>

      <AudioPlayButton page={props.page} />
      <ProgressWrap page={props.page}>
        <AudioPlayProgress page={props.page} />
      </ProgressWrap>
    </>
  )
};