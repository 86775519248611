import { useSelector } from 'react-redux';
import styled, { css } from "styled-components";
import { RootState } from "../../../store";

export interface ProgressTimeProps {
  kind : string,
  page : boolean,
};

const TimeWrap = styled.div<ProgressTimeProps>`
  font-size: 1.5625vw;
  ${(props) => props.kind === 'audio' && css`font-weight: 600;`}
  color: ${(props) => props.kind === 'video' ? '#fff' : '#5493fc'};

  ${(props) => props.page && css`
    font-size: 2.083333vw;
  `}
`;

const Time = styled.span<ProgressTimeProps>`
  font-size: 1.5625vw;
  ${(props) => props.kind === 'audio' && css`font-weight: 600;`}
  color: ${(props) => props.kind === 'video' ? '#fff' : '#5493fc'};

  ${(props) => props.page && css`
    font-size: 2.083333vw;
  `}
`;

export const ProgressTime = ({...props}: ProgressTimeProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const video = useSelector((state : RootState) => state.video);

  return (
    <TimeWrap page={props.page} kind={props.kind}>
      <Time page={props.page} kind={props.kind}>{props.kind === 'audio' ? audio.current_time : video.current_time}</Time>
      &nbsp;/&nbsp;
      <Time page={props.page} kind={props.kind}>{props.kind === 'audio' ? audio.duration_time : video.duration_time}</Time>
    </TimeWrap>
  )
};