import { ResourcesTemplate } from "../../_templates/ResourcesTemplate/resourcesTemplate";

export interface ResourcesProps {
  
};

export const Resources = ({...props}: ResourcesProps) => {
  return (
    <ResourcesTemplate />
  )
};