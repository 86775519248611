import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { AudioTrack } from "../../../asset/utils/AudioTrack";
import { RootState } from "../../../store";
import { setAudioPlay, setCurrentTrack, setCurrentUnit, setRewind } from "../../../store/AudioSlice";
import { Button } from "../../_atoms/Button/button";

export interface AudioPlayButtonProps {
  page : boolean
};

const BtnWrap = styled.div`
  align-self: center;
  justify-self: center;
`;

export const AudioPlayButton = ({...props}: AudioPlayButtonProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const onAudioPrev = () => {
    // 첫번째 트랙
    if (audio.current_track === 0 && audio.current_unit !== 0) {
      dispatch(setCurrentUnit(audio.current_unit - 1));
      dispatch(setCurrentTrack(AudioTrack[book.current_book - 1][audio.current_unit - 1].length -1));
    } else if (audio.current_track > 0) {
      dispatch(setCurrentTrack(audio.current_track - 1));
    }
  };

  const onAudioNext = () => {
    // 마지막 트랙
    if (audio.current_track === AudioTrack[book.current_book - 1][audio.current_unit].length - 1 && audio.current_unit !== AudioTrack[book.current_book - 1].length - 1) {
      dispatch(setCurrentUnit(audio.current_unit + 1));
      dispatch(setCurrentTrack(0));
    } else if (audio.current_track < AudioTrack[book.current_book - 1][audio.current_unit].length - 1) {
      dispatch(setCurrentTrack(audio.current_track + 1));
    }
  };

  return (
    <BtnWrap>
      <Button kind="audio" name="prev" page={props.page} onClick={() => onAudioPrev()} />
      <Button kind="audio" name="play" page={props.page} act={audio.play} onClick={() => {
        dispatch(setAudioPlay(!audio.play));
        dispatch(setRewind(false));
      }} />
      <Button kind="audio" name="next" page={props.page} onClick={() => onAudioNext()} />
    </BtnWrap>
  )
};