import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { RootState } from "../../../store";
import { setBtnSound } from "../../../store/CommonSlice";
import { Heading } from "../../_atoms/Heading/heading";
import { Paragraph } from "../../_atoms/Paragraph/paragraph";
import { Spinner } from "../../_atoms/Spinner/spinner";
import { Frame } from "../../_molecules/Frame/frame";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";
import { ResourcesWrap } from "../../_organisms/ResourcesWrap/resourcesWrap";

export interface ResourcesTemplateProps {
  
};

const Wrap = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const ResourcesTemplate = ({...props}: ResourcesTemplateProps) => {
  const common = useSelector((state : RootState) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Frame kind="resource">
      <ImageButton
        kind="home"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_home_150px.png"
        alt="HOME"
        onClick={() => {
          dispatch(setBtnSound(true));
          navigate("/");
        }} />
      <ImageButton
        kind="back"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_pre_150px.png"
        alt="BACK"
        onClick={() => {
          dispatch(setBtnSound(true));
          navigate("/menu");
        }} />

      <Wrap>
        <Heading level={1} kind="resource">
          Learning Resources
        </Heading>

        <Paragraph kind="resource">Click the icon and open the file you want.</Paragraph>

        <ResourcesWrap />
      </Wrap>

      {common.loading && (
        <Spinner
          type="BarLoader"
          color="#5493fc"
          height={12}
          speedMultiplier={0.8}
          width={800}
          override={{bottom : '17vw'}}
        />  
      )}
    </Frame>
  )
};