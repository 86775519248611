import styled, { css } from "styled-components";

export interface AsideProps {
  kind : string,
  act? : boolean,
  children : React.ReactNode,
};

const AsideTag = styled.aside<AsideProps>`
  ${(props) => {
    switch (props.kind) {
      case "menu":
        return css`
          position: absolute;
          top: 0;
          z-index: 4;
          width: 8.4375vw;
          height: 100%;
          padding: 0.78125vw;
          background-color: #eee;
          filter: drop-shadow(0.260417vw 0.260417vw 0.260417vw rgba(0,0,0,0.20));
          transition: left 0.3s;
          text-align: center;

          left : ${props.act ? '0' : '-8.4375vw'};
        `
    }
  }}
`;

export const Aside = ({...props}: AsideProps) => {
  return (
    <AsideTag kind={props.kind} act={props.act}>
      {props.children}
    </AsideTag>
  )
};