import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play : false,
  rewind : false,
  repeat : false,
  current_unit : 0,
  current_track : 0,
  duration_time : '00:00',
  current_time : '00:00',
  current_range : 0,
};

const audio = createSlice({
  name : "audio",
  initialState,
  reducers : {
    resetAudio : () => initialState,
    setAudioPlay(state, action) {
      state.play = action.payload;
    },
    setRewind(state, action) {
      state.rewind = action.payload;
    },
    setRepeat(state, action) {
      state.repeat = action.payload;
    },
    setCurrentUnit(state, action) {
      state.current_unit = action.payload;
    },
    setCurrentTrack(state, action) {
      state.current_track = action.payload;
    },
    setAudioDurationTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      let second = parseInt(action.payload) - minute * 60;

      let min = minute < 10 ? "0" + minute.toString() : minute;
      let sec = second < 10 ? "0" + second.toString() : second;

      state.duration_time = min + ":" + sec;
    },
    setAudioCurrentTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      let second = parseInt(action.payload) - minute * 60;

      let min = minute < 10 ? "0" + minute.toString() : minute;
      let sec = second < 10 ? "0" + second.toString() : second;

      state.current_time = min + ":" + sec;
    },
    setAudioCurrentRange(state, action) {
      state.current_range = action.payload;
    },
  }
});

export const {
  resetAudio,
  setAudioPlay,
  setRewind,
  setRepeat,
  setCurrentUnit,
  setCurrentTrack,
  setAudioDurationTime,
  setAudioCurrentTime,
  setAudioCurrentRange,
} = audio.actions;

export default audio;