import styled, { css } from "styled-components";

export interface MainProps {
  kind : string,
  book? : number,
  children : React.ReactNode;
};

const MainTag = styled.main<MainProps>`
  position: relative;

  ${(props) => (props.kind === 'bookMenu' && css`
    background: center /contain no-repeat;
    background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main_bg.png) center /contain no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 4.166667vw;
    padding-top: 15.416667vw;
  `)}

  ${(props) => (props.kind === 'menu' && css`
    background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main_bg.png) center /contain no-repeat;
  `)}

  ${(props) => props.kind === 'audio' && css`
    display: grid;
    background: center /contain no-repeat;
    position: relative;
    grid-template-columns: 20% 80%;
    grid-template-rows: auto 8.072917vw 7.291667vw;
    grid-row-gap: 3.211806vw;

    ${props.book === 0 && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_book1_bg_small.png);'} 
    ${props.book === 1 && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_book2_bg_small.png);'} 
    ${props.book === 2 && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_book3_bg_small.png);'} 
  `}

  ${(props) => props.kind === 'ebook' && css`
    background-color: #f2f2f2;
  `}

  ${(props) => props.kind === 'resource' && css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/LR_mainbg.png) center / cover no-repeat;
  `}
`;

export const Main = ({...props}: MainProps) => {
  return (
    <MainTag
      kind={props.kind}
      book={props.book}
    >
      {props.children}
    </MainTag>
  )
};