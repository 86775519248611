import styled, { css } from "styled-components";

export interface ImageProps {
  src : string,
  alt : string,
  kind : string,
  act? : boolean
};

const ImageTag = styled.img<ImageProps>`
  -webkit-tap-highlight-color :  rgba(255, 255, 255, 0); 
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  ${(props) => (props.kind === 'bookMenu' && css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  `)}

  ${(props) => (props.kind === 'home' && css`
    width: 100%;
    height: auto;
  `)}

  ${(props) => (props.kind === 'back' || props.kind === 'close') && css`
    width : 100%;
    height : 100%;
  `}

  ${(props) => (props.kind === 'ebook' && css`
    position: absolute;
    top: 1.875vw;
    left: 50%;
    transform: translateX(-50%);
    width: 72.916667vw;
    height: auto;
    z-index: 0;
    filter: drop-shadow(0 0.15625vw 0.15625vw rgba(0,0,0,0.15));
  `)}

  ${(props) => (props.kind === 'page' && css`
    width: 100%;
    height: 100%;
  `)}

  ${(props) => (props.kind === 'logo' && css`
    width: 100%;
    height: auto;
    margin-bottom: 0.78125vw;
  `)}

  ${(props) => (props.kind === 'book' && css`
    position: absolute;
    top: 1.875vw;
    left: 50%;
    transform: translateX(-50%);
    width: 72.916667vw;
    height: auto;
    z-index: 0;
    ${!props.act && 'display : none;'}
  `)}

  ${(props) => props.kind === 'memoClose' && css`
    width: 100%;
    height: 100%;
  `}
`;

export const Image = ({...props}: ImageProps) => {
  return (
    <ImageTag 
      kind={props.kind}
      src={props.src}
      alt={props.alt}
      act={props.act}
    />
  )
};