import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from "styled-components";
import { RootState } from "../../../store";
import { resetAudio } from '../../../store/AudioSlice';
import { resetCard } from '../../../store/CardSlice';
import { closePop } from '../../../store/CommonSlice';
import { resetGame } from '../../../store/GameSlice';
import { closeMenu } from '../../../store/MenuSlice';
import { resetVideo } from '../../../store/VideoSlice';
import { ImageButton } from '../../_molecules/ImageButton/imageButton';

export interface PopupWrapProps {
  kind : string,
  menu? : boolean,
  book? : number,
  children : React.ReactNode,
};

const Back = styled.div`
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 56.25vw;
`;

const Popup = styled.div<PopupWrapProps>`
  position: absolute;
  top: 50%;
  left: ${(props) => props.menu ? 'calc(50% + 4.21875vw)' : '50%'};
  transform: translate(-50%, -50%);
  transition: left 0.3s;

  ${(props) => props.kind === 'video' && css`
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 26.770833vw;
  `}

  ${(props) => props.kind === 'card' && css`
    width: 60vw;
    height: 39.375vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/flashCard/flashcard_book1_bg_gray.png);
  `}

  ${(props) => props.kind === 'audio' && css`
    width: 70vw;
    height: 39.375vw;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto 5.651042vw 7.108334vw;
    overflow: hidden;
    background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_book${props.book}_bg_small.png) center / cover;'
  `}

  ${(props) => props.kind === 'game' && css`
    background: center /cover no-repeat;
    width: 68.645833vw;
    height: 45.208333vw;
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/game${props.book}_bg.png);
  `}
`;

export const PopupWrap = ({...props}: PopupWrapProps) => {
  const book = useSelector((state : RootState) => state.book);
  const menu = useSelector((state : RootState) => state.menu);
  const dispatch = useDispatch();
  
  return (
    <Back>
      <Popup kind={props.kind} menu={menu.act} book={book.current_book}>
        {props.children}
      </Popup>
      <ImageButton 
        kind="close"
        name={props.kind}
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/popUp_close.png"
        alt="close"
        onClick={() => (
          dispatch(closePop()),
          dispatch(resetAudio()),
          dispatch(resetVideo()),
          dispatch(resetCard()),
          dispatch(resetGame()),
          dispatch(closeMenu())
        )}
      />
    </Back>
  )
};