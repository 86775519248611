import { useEffect, useState } from "react";
import styled from "styled-components";

export interface SvgProps {
  
};

const SvgTag = styled.svg`
  width: 100%;
  height : 100%;
`;

export const Svg = ({...props}: SvgProps) => {
  const iframe = window.document.all[25] as HTMLIFrameElement;
  const canvas = iframe.contentWindow?.document.querySelector('canvas');

  const [width, setWidth] = useState(canvas && canvas.width);
  const [height, setHeight] = useState(canvas && (canvas.height - (canvas.height * 0.084375)));
  const [position, setPosition] = useState({x : width! * 0.5, y : height! * 0.5});
  const [screen, setScreen] = useState({x: 0, y : 0});
  const [isDown, setIsDown] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => {
      window.addEventListener('resize', handleSize);
    };
  });

  const handleSize = () => {
    setWidth(canvas?.width);
    setHeight(canvas!.height);
  };

  const onMouseDown = (e : React.MouseEvent<HTMLOrSVGElement>) => {
    setIsDown(true);
    setScreen({x : e.screenX, y : e.screenY});
  };

  const onMouseMove = (e : React.MouseEvent<HTMLOrSVGElement>) => {
    const shiftX = e.screenX - screen.x;
    const shiftY = e.screenY - screen.y;
    
    if (isDown) {
      setPosition({x : position.x + shiftX, y : position.y + shiftY});
      setScreen({x : e.screenX, y : e.screenY});
    }
  };

  const onMouseUp = (e : React.MouseEvent<HTMLOrSVGElement>) => {
    setIsDown(false);
    setScreen({x : 0, y : 0});
  };

  const onTouchStart = (e : React.TouchEvent<HTMLOrSVGElement>) => {
    setIsDown(true);
    setScreen({x : e.touches[0].pageX, y : e.touches[0].pageY});
  };

  const onTouchMove = (e : React.TouchEvent<HTMLOrSVGElement>) => {
    const shiftX = e.touches[0].pageX - screen.x;
    const shiftY = e.touches[0].pageY - screen.y;
    
    if (isDown) {
      setPosition({x : position.x + shiftX, y : position.y + shiftY});
      setScreen({x : e.touches[0].pageX, y : e.touches[0].pageY});
    }
  };

  const onTouchEnd = (e : React.TouchEvent<HTMLOrSVGElement>) => {
    setIsDown(true);
    setScreen({x : 0, y : 0});
  };

  return (
    <SvgTag>
      <mask id="myMask">
        <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
          <circle
            cx={position.x}
            cy={position.y}
            r="7.8125vw" 
            fill="black"
          />
      </mask>
      <rect
        x="0" 
        y="0" 
        width="100%" 
        height="100%" 
        fill="#000" 
        mask="url(#myMask)"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      />
    </SvgTag>
  )
};