import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { RootState } from "../../../store";
import { setDiceAct } from "../../../store/GameSlice";

export interface DiceProps {
  book? : number,
  onClick : () => void,
};

const DiceBody = styled(motion.div)<DiceProps>`
  background: center /contain no-repeat;
  position: absolute;
  transform-origin: 50% 50%;
  width: 6.25vw;
  height: 6.354167vw;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/dice1.png);
  cursor: pointer;

  ${(props) => props.book === 0 && css`
    top: 13%;
    right: 14.4%;
  `}

  ${(props) => props.book === 1 && css`
    top: 13%;
    right: 28%;
  `}

  ${(props) => props.book === 2 && css`
    top: 5%;
    right: 16%;
  `}
`;

export const Dice = ({...props}: DiceProps) => {
  const game = useSelector((state : RootState) => state.game);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const timerRef = useRef<any>();
  const [second, setSecond] = useState(0);

  const [animate, setAnimate] = useState<any>({
    x: 0, y: [0, -40, 0, -40, 0], z: 0,
    transition : {
      duration: 2,
      ease: "easeInOut",
      times: [0, 0.2, 0.5, 0.8, 0.5, 1],
      repeat: Infinity,
      repeatDelay: 1.5
    }
  });

  useEffect(() => {
    clearInterval(timerRef.current);
    setSecond(0);
    
    if (game.dice_act) {
      setAnimate({
        x: [0, -20, 0], y:[0, -40, 0], z: 0,
        rotate: [0, 120, 240, 360, 480, 600, 720, 840, 960, 1080],
        backgroundImage: "url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/dice"+game.dice+".png)",
        transition : {
          duration: 1,
          ease: "linear",
        }
      });
    } else {
      setAnimate({
        x: 0, y: [0, -40, 0, -40, 0], z: 0,
        rotate: [360],
        backgroundImage: "url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/dice"+game.dice+".png)",
        transition : {
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 0.5, 1],
          repeat: Infinity,
          repeatDelay: 1.5
        }
      });
    }
  }, [game.dice_act])

  useEffect(() => {
    if (!game.dice_act) {
      clearInterval(timerRef.current);
      setSecond(0);
    } else {
      second === 1 && dispatch(setDiceAct(false));
    }
  }, [second]);

  const onAnimationComplete = () => {
    if (game.dice_act) {
      timerRef.current = setInterval(() => {
        setSecond(second + 1);
      }, 2000);
      
      return () => {
        clearInterval(timerRef.current);
        setSecond(0);
      };
    }
  };

  return (
    <DiceBody 
      book={book.current_book}
      initial="normal"
      animate={animate}
      onAnimationComplete={() => onAnimationComplete()}
      onClick={props.onClick}
    />
  )
};