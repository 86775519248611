import { useEffect, useRef } from "react";
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from "react-router";
import styled from "styled-components";
import { RootState } from "../../../store";
import { setBtnSound } from "../../../store/CommonSlice";

export interface ButtonMusicProps {
  
};

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const ButtonMusic = ({...props}: ButtonMusicProps) => {
  const state = useSelector((state : RootState) => state.common);
  const dispatch = useDispatch();
  
  const player = useRef<H5AudioPlayer>(null);

  useEffect(() => {
    if (player.current instanceof H5AudioPlayer) {
      if (state.btn_sound) {
        player.current.audio.current?.play();
        dispatch(setBtnSound(false));
      } 
    }
  });

  useEffect(() => {
    if (state.btn_sound) {

    }
  }, [state.btn_sound]);

  return (
    <>
      <AudioPlayer
        ref={player}
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/mp3/btn.wav"
        autoPlay={false}
      />
      <Outlet />
    </>
  )
};
