import { Frame } from "../../_molecules/Frame/frame";
import { AudioListWrap } from "../../_organisms/AudioListWrap/audioListWrap";
import { AudioPlayerWrap } from "../../_organisms/AudioPlayerWrap/audioPlayerWrap";

export interface AudioTemplateProps {
  
};

export const AudioTemplate = ({...props}: AudioTemplateProps) => {
  return (
    <Frame kind="audio">
      <AudioListWrap page={true} />
      <AudioPlayerWrap page={true} />
    </Frame>
  )
};