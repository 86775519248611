import { createGlobalStyle } from "styled-components";

export const IframeGlobalStyle = createGlobalStyle`
  /* font */
  @font-face {
    font-family: 'BalooBhaijaan';
    font-weight: 400 800;
    font-style: normal;
    font-display: swap;
    src: local(※), url('/resource/font/BalooBhaijaan.ttf') format('truetype');
  }

  /* Common Settings */
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
  }

  ul, ol {
    list-style: none;
  }

  a {
    color: unset;
    text-decoration: none;
  }

  img {
    border: none;
  }

  button {
    appearance: none;
    border: none;
    outline: unset;
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    background-color: initial;
  }

  input {
    appearance: none;
    border: none;
    background-color: transparent;
  }

  input:focus {
    outline-color: #333
  }

  ::-webkit-scrollbar {
    width: 0.416667vw;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Based on screen size: 1920*1080 */
  html, body {
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    height: 100%;
    font-family: 'BalooBhaijaan';
  }

  body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrap {
    width: 100vw;
    height: 56.25vw;
  }

  main {
    width: 100%;
    height: 100%;
  }
`;