import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from "styled-components";
import { RootState } from '../../../store';

export interface ItemProps  {
  kind : string,
  act? : boolean,
  page? : boolean,
  idx? : number,
  className? : string,
  children? : React.ReactNode,
};

const LiTag = styled.li<ItemProps>`
  ${(props) => (props.kind === 'unit' || props.kind === 'track') && css`
    border-bottom: 0.052083vw solid #e5e5e5;
  `}

  ${(props) => (props.kind === 'aside' && css`
    position: relative;
    height: 1.822917vw;
  `)}

  ${(props) => (props.kind === 'asideIn' && css`
    position: relative;
    width: 100%;
    height: 2.083333vw;
    background-color: #fff;
  `)};

  ${(props) => (props.kind === 'animation' && css`
    width: 100%;
    height: 2.083333vw;
    background-color: #fff;
    position: relative;
  `)}

  ${(props) => props.kind === 'dots' && css`
    width: 0.520833vw;
    height: 0.520833vw;
    background-color: #bbb;
  `}

  ${(props) => props.kind === 'card' && css`
    width: 11.588542vw;
    height: 14.322917vw;
  `}
`;

export const Item = ({...props} : ItemProps) => {
  const audio = useSelector((state : RootState) => state.audio);

  const itemRef = useRef<HTMLLIElement>(null);
  
  useEffect(() => {
    if (!props.page && itemRef.current && props.idx === audio.current_unit) {
      itemRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }, [audio.current_unit]);

  return (
    <LiTag kind={props.kind} act={props.act} className={props.className} ref={itemRef}>
      {props.children}
    </LiTag>
  )
};