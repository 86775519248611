import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { setCurrentBook } from '../../../store/BookSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { Frame } from "../../_molecules/Frame/frame";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";

export interface MainTemplateProps {
  menu : {src : string, alt : string}[];
};

export const MainTemplate = ({...props}: MainTemplateProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Frame kind="bookMenu">
      {props.menu && props.menu.map((book, idx) => {
        return <ImageButton 
                kind='bookMenu' 
                src={book.src} 
                alt={book.alt} 
                onClick={() => {
                  dispatch(setCurrentBook(idx + 1));
                  navigate("/menu");
                }}
                onMouseOver={() => dispatch(setBtnSound(true))}
              />
      })}
    </Frame>
  )
};