import styled, { css } from "styled-components";

export interface SpanProps {
  kind : string,
  src? : string,
  wsrc? : string,
  act? : boolean,
  choice? : boolean,
  back? : boolean,
  reset? : boolean,
  className? : string,
  onClick? : () => void,
  children? : React.ReactNode,
};

const SpanTag = styled.span<SpanProps>`
  ${(props) => props.kind === 'card' && css`
    display: block;
    transform-origin: center;
    cursor: pointer;
    background: center /contain no-repeat;
    width: 11.588542vw;
    height: 14.322917vw;
    
    ${props.back ? 'background-image: url("'+ props.wsrc+'")' : 'background-image: url("'+props.src+'")'};

    &.ani1 {
      transform: scale(100%);
      animation: ani1 0.6s ease-in-out;
      animation-play-state: running;
    }
  
    @keyframes ani1 {
      0% {
        transform: scale(100%);
      }
  
      50% {
        transform: scale(110%);
      }
  
      100% {
        transform: scale(100%);
      }
    }

    &.ani2 {
      animation: ani2 0.6s ease-in-out;
      animation-play-state: running;
    }

    @keyframes ani2 {
      0% {
        transform: scale(100%);
      }

      50% {
        transform: scale(110%);
      }

      100% {
        transform: scale(100%);
      }
    }

    &.ani3 {
      transform: scale(100%);
      animation: ani3 0.6s ease-in-out;
      animation-play-state: running;
    }

    @keyframes ani3 {
      0% {
        transform: scale(70%);
      }

      100% {
        transform: scale(100%);
      }
    }

    &.ani4 {
      transform: scale(70%);
      animation: ani4 0.6s ease-in-out;
      animation-play-state: running;
    }

    @keyframes ani4 {
      0% {
        transform: scale(100%);
      }

      100% {
        transform: scale(70%);
      }
    }
  `}
`;

export const Span = ({...props}: SpanProps) => {
  return (
    <SpanTag
      kind={props.kind} 
      src={props.src} 
      wsrc={props.wsrc}
      act={props.act}
      choice={props.choice}
      back={props.back}
      reset={props.reset}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </SpanTag>
  )
};