import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from "../../../store";
import { setClear, setMaskAct, setMemoAct, setMode, setUndo } from '../../../store/ToolSlice';
import { Button } from "../../_atoms/Button/button";
import { Heading } from "../../_atoms/Heading/heading";

export interface EbookToolBoxProps {
  
};

const DivTag = styled.div`
  padding: 0.520833vw 0;
  margin-bottom: 0.78125vw;
  background-color: #fff;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 2.604167vw);
  grid-template-rows: repeat(5,auto);
  grid-column-gap: 0.520833vw;
  justify-content: center;
`;

export const EbookToolBox = ({...props}: EbookToolBoxProps) => {
  const tool = useSelector((state : RootState) => state.tool);
  const dispatch = useDispatch();

  return (
    <DivTag>
      <Heading level={1} kind="tool">Tool Box</Heading>
      <Button kind="tool" name="select" act={tool.tool_act && tool.mode === 'S' ? true : false} onClick={() => dispatch(setMode('S'))}/>
      <Button kind="tool" name="memo" act={tool.memo_act ? true : false} onClick={() => dispatch(setMemoAct(!tool.memo_act))}/>
      <Button kind="tool" name="pen" act={tool.tool_act && tool.mode === 'P' ? true : false} onClick={() => dispatch(setMode('P'))}/>
      <Button kind="tool" name="color" act={tool.tool_act && tool.mode === 'R' ? true : false} onClick={() => dispatch(setMode('R'))}/>
      <Button kind="tool" name="eraser" onClick={() => dispatch(setUndo(true))}/>
      <Button kind="tool" name="reset" onClick={() => dispatch(setClear(true))}/>
      <Button kind="tool" name="telescope" act={tool.mask_act ? true : false} onClick={() => dispatch(setMaskAct(!tool.mask_act))}/>
    </DivTag>
  )
};