import styled from "styled-components";

export interface AudioProps {
  autoplay : string,
  src : string,
  act : boolean,
  bottom : number,
  left : number,
};

const AudioTag = styled.audio<AudioProps>`
  position: absolute;
  bottom: ${(props) => props.bottom}vw;
  left: ${(props) => props.left}vw;
  transform: translateX(-50%);
  z-index: 2;
  filter: drop-shadow(0 0.052083vw 0.104167vw rgba(0, 0, 0, 0.15));

  display : ${(props) => props.act ? 'block' : 'none'};

  &::-webkit-media-controls-panel {
    background-color: #fff;
  }

  &::-webkit-media-controls-volume-control-container, &::-webkit-media-controls-current-time-display, &::-webkit-media-controls-time-remaining-display {
    display: none;
  }
`;

export const Audio = ({...props}: AudioProps) => {
  return (
    <AudioTag
      autoplay={props.autoplay}
      src={props.src}
      controls
      controlsList='nodownload noplaybackrate'
      act={props.act}
      bottom={props.bottom}
      left={props.left}
    />
  )
};