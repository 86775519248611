import { useSelector } from "react-redux";
import { BarLoader, BeatLoader, ClipLoader, DotLoader, FadeLoader, GridLoader, MoonLoader, PulseLoader, SyncLoader } from 'react-spinners';
import styled from "styled-components";
import { RootState } from "../../../store";

export interface SpinnerProps {
  type?: string,
  loading?: boolean,
  color? : string,
  width? : number,
  height? : number,
  speedMultiplier? : number,
  override? : React.CSSProperties,
};

const Bar = styled(BarLoader)`
  @keyframes react-spinners-BarLoader-long {
    0% {
      left: -35%;
      right: 100%;
    }

    60% {
      left: 100%;
      right: -90%;
    }

    100% {
      left: 100%;
      right: -90%;
    }
  }

  @keyframes react-spinners-BarLoader-short {
    0% {
      left: -200%;
      right: 100%;
    }

    60% {
      left: -200%;
      right: 100%;
    }

    100% {
      left: 107%;
      right: -8%;
    }
  }
`

export const Spinner = ({...props}: SpinnerProps) => {
  const common = useSelector((state : RootState) => state.common);

  return (
    <>
      {props.type === "GridLoader" && (
        <GridLoader
          cssOverride={props.override}
          color="#36d7b7"
        />
      )}

      {props.type === "MoonLoader" && (
        <MoonLoader
          cssOverride={props.override}
          color="#36d7b7"
        />
      )}

      {props.type === "BarLoader" && (
        <Bar
          cssOverride={props.override}
          color={props.color}
          width={props.width}
          height={props.height}
          speedMultiplier={props.speedMultiplier}
        />
      )}

      {props.type === "ClipLoader" && (
        <ClipLoader
          cssOverride={props.override}
          color="#0854A0"
        />
      )}

      {props.type === "SyncLoader" && (
        <SyncLoader
          cssOverride={props.override}
          color="#0854A0"
        />
      )}

      {props.type ===  "PulseLoader" && (
        <PulseLoader
          cssOverride={props.override}
          color="#0854A0"
        />
      )}

      {props.type === "FadeLoader" && (
        <FadeLoader
          cssOverride={props.override}
          color="#0854A0"
        />
      )}

      {props.type === "BeatLoader" && (
        <BeatLoader
          cssOverride={props.override}
          color="#0854A0"
          speedMultiplier={1.5}
        />
      )}

      {props.type === "DotLoader" && (
        <DotLoader
          cssOverride={props.override}
          color="#0854A0"
          speedMultiplier={1.5}
        />
      )}
    </>
  )
};