
import { useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ContentBookPath } from '../../../asset/utils/Constant';
import { RootState } from '../../../store';
import { setSlide, setWordPlay } from '../../../store/CardSlice';
import { Item } from '../../_atoms/Item/item';
import { List } from '../../_atoms/List/list';
import { Span } from '../../_atoms/Span/span';

export interface FlashCardListProps {
  card : {
    act? : boolean,
    choice? : boolean,
    word : string[][],
    back : boolean,
    reset : boolean,
    src : string,
    wsrc : string,
  }[],
  idx : number,
};

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const FlashCardList = ({...props}: FlashCardListProps) => {
  const card = useSelector((state : RootState) => state.card);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const [mp3, setMp3] = useState<string>("");

  const player = useRef<H5AudioPlayer>(null);

  const onCardClick = (idx : number, i : number) => {
    if (card.word_play) {
      return false;
    }

    const slideArray = card.slide[idx].map((slide, num : number) => {
      if (i === num) {
        return {
          ...slide,
          act : true,
          choice : true  
        }
      } else {
        return {
          ...slide,
          act : true,
          choice : false  
        }
      }
    });

    dispatch(setSlide([
      ...card.slide.slice(0, idx),
      slideArray,
      ...card.slide.slice(idx + 1)
    ]));

    setMp3(ContentBookPath + book.current_book + '/flashCard/mp3/' + card.slide[idx][i].word + '.mp3');
    dispatch(setWordPlay(true));
  };

  const onWordPlayEnd = (idx : number) => {
    setMp3('');
    dispatch(setWordPlay(false));

    const slideArray = card.slide[idx].map((slide) => {
      if (slide.act === true && slide.choice === true) {
        return {
          ...slide,
          act : false,
          reset : true,
          back : !slide.back
        }
      } else {
        return {
          ...slide,
          act : false,
          reset : true
        }
      }
    });

    dispatch(setSlide([
      ...card.slide.slice(0, idx),
      slideArray,
      ...card.slide.slice(idx + 1)
    ]));
  };

  return (
    <>
      <List order={false} kind="card">
        {props.card.map((word, i) => {
          return (
            <Item kind="card">
              <Span
                kind="card" 
                src={word.src} 
                wsrc={word.wsrc}
                act={word.act}
                choice={word.choice}
                back={word.back}
                reset={word.reset}
                className={word.act ? (word.choice ? 'ani1 ani2' : 'ani3 ani4') : (word.choice ? 'ani1' : 'ani3')}
                onClick={() => onCardClick(props.idx, i)}
              />
            </Item>
          )
        })}
      </List>
      <AudioPlayer
        ref={player}
        src={mp3}
        autoPlayAfterSrcChange={card.word_play}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        onEnded={() => onWordPlayEnd(props.idx)}
      />
    </>
  )
};