import H5AudioPlayer from "react-h5-audio-player";
import styled, { css } from "styled-components";

export interface InputProps {
  type : string,
  name : string,
  video? : boolean,
  min? : number,
  max? : number,
  step? : number,
  value? : number | string,
  page? : boolean,
  player? : H5AudioPlayer | null,
  onChange : (e: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown? : (e: React.KeyboardEvent<HTMLInputElement>) => void,
};

const InputTag = styled.input<InputProps>`
  ${(props) => props.name === 'progress' && css`
    ${props.video ? css`
      width: 20vw;
      height: 0.625vw;
      border-radius: 0.3125vw;
    ` : css`
      ${props.page ? css`
        width: 41.666667vw;
        height: 1.09375vw;
        border-radius: 0.546875vw;
      ` : css`
        width: 29.166667vw;
        height: 0.875vw;
        border-radius: 0.4375vw;
      `}
    `}
    
    background: linear-gradient(to right, rgb(84, 147, 252) ${props.value}%, rgb(213, 213, 213) ${props.value}%);

    &::-webkit-slider-runnable-track {
      ${props.video ? css`
        width: 20vw;
        height: 0.625vw;
        border-radius: 0.3125vw;
      ` : css`
        width: 29.166667vw;
        height: 0.875vw;
        border-radius: 0.4375vw;
      `}
    }

    &:focus {
      outline-color: #333;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      filter: drop-shadow(0 .104167vw .15625vw rgba(0, 0, 0, 0.4));
      ${props.video ? css`
        width: 2.25vw;
        height: 2.3125vw;
        transform: translateY(calc(-50% + 0.625vw * 0.5));
        background: transparent url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/audio_progressbar_ball.png) right center / contain no-repeat;
      ` : css`
        width: 2.625vw;
        height:2.697917vw;
        transform: translateY(calc(-50% + 1.09375vw * 0.5));
        background: transparent url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/audio_progressbar_ball.png) right center / cover;
      `}
    }
  `}

  ${(props) => (props.name === 'page' && css`
    width: 4.166667vw;
    height: 50%;
    text-align: center;
    font-size: 0.9375vw;
    color: #333;
  `)}
`;

export const Input = ({...props}: InputProps) => {
  return (
    <InputTag
      type={props.type}
      name={props.name}
      video={props.video}
      min={props.min}
      max={props.max}
      step={props.step}
      value={props.value}
      page={props.page}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
    />
  )
};