import { useDispatch, useSelector } from 'react-redux';
import { SlideMenuList } from "../../../asset/utils/SlideMenuList";
import { RootState } from "../../../store";
import { closeMenu, setAct } from '../../../store/MenuSlice';
import { Aside } from "../../_atoms/Aside/aside";
import { Button } from "../../_atoms/Button/button";
import { Image } from "../../_atoms/Image/image";
import { EbookMenuList } from "../../_molecules/EbookMenuList/ebookMenuList";
import { EbookToolBox } from '../../_molecules/EbookToolBox/ebookToolBox';

export interface EbookMenuWrapProps {
  
};

export const EbookMenuWrap = ({...props}: EbookMenuWrapProps) => {
  const menu = useSelector((state : RootState) => state.menu);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();
  
  return (
    <Aside kind="menu" act={menu.act}>
      <Button 
        kind="ebookMenu" 
        act={menu.act}
        onClick={() => {
          dispatch(setAct(!menu.act));
          dispatch(closeMenu());
        }}
      />
      <Image 
        kind='logo'
        src='https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/left-menu_logo.png'
        alt='Phonics Hero'
      />
      {SlideMenuList[book.current_book - 1].map((slideMenu, idx) => {
        return <EbookMenuList list={slideMenu} depth1={idx}/>
      })}

      <EbookToolBox />
    </Aside>
  )
};