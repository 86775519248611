import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { RootState } from "../../../store";

export interface ButtonProps {
  kind : string,
  name? : string,
  animal? : "rabbit" | "fox" | "sheep",
  page? : boolean,
  act? : boolean
  menu? : boolean,
  book? : number,
  bottom? : number,
  left? : number,
  className? : string,
  disabled? : boolean,
  children? : React.ReactNode,
  onClick? : () => void,
  onMouseOver? : () => void,
};

const ButtonTag = styled.button<ButtonProps>`
  appearance: none;
  background-color: initial;
  border: none;
  outline: unset;
  box-shadow: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background: center /contain no-repeat;

  -webkit-tap-highlight-color :  rgba(255, 255, 255, 0); 
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  ${(props) => (props.kind === 'bookMenu' && css`
    position: relative;
    width: 20.364583vw;
    height: 26.354167vw;
    filter: drop-shadow(0.520833vw 0.520833vw rgba(0,0,0,0.15));
    transition: filter 0.6s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      filter: drop-shadow(0 0 rgba(0,0,0,0.15));

      img {
        top:0.520833vw;
        left:0.520833vw;
      }
    }
  `)}

  ${(props) => (props.kind === 'menu' && css`
    background-size: contain;
    position: absolute;

    &:hover::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      height: 8.177083vw;
      background-size: contain;
    }

    ${props.animal === "rabbit" && css`
      width: 15.729167vw;
      height: 25.885417vw;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/rabbit.png);
      top: 26.302083vw;
      left: 14.270833vw;

      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/rabbit_h.png);

        &::after {
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/rabbit_tooltip.png);
          width: 22.604167vw;
        }
      }
    `}

    ${props.animal === "fox" && css`
      width: 18.854167vw;
      height: 24.21875vw;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/fox.png);
      top: 27.96875vw;
      left: 40.15625vw;

      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/fox_h.png);

        &::after {
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/fox_tooltip.png);
          width: 22.552083vw;
        }
      }
    `}

    ${props.animal === "sheep" && css`
      width: 20.052083vw;
      height: 23.28125vw;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/sheep.png);
      top: 28.90625vw;
      left: 68.958333vw;

      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/sheep_h.png);

        &::after {
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/sheep_tooltip.png);
          width: 29.739583vw;
          height: 8.125vw;
        }
      }
    `}
  `)}

  ${(props) => props.kind === 'home' && css`
    left: 93.822917vw;
    transition: transform 0.1s;
    width: 3.385417vw;
    top: 1.666667vw;
    position: absolute;
    z-index: 2;

    &:active {
      transform: scale(90%);
      transform-origin: center;
    }
  `}

  ${(props) => props.kind === 'back' && css`
    left: 93.822917vw;
    transition: transform 0.1s;
    width: 3.385417vw;
    top: 5.666667vw;
    position: absolute;
    z-index: 2;

    &:active {
      transform: scale(90%);
      transform-origin: center;
    }
  `}

  ${(props) => props.kind === 'audio' && css`
    background: center /contain no-repeat;
    width: 6.234375vw;
    height: 5.651042vw;

    ${props.page && css`
      width: 8.90625vw;
      height: 8.072917vw;
    `}

    ${props.name === 'next' && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_next.png);'}
    ${props.name === 'prev' && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_pre.png);'}
    ${props.name === 'play' && 'margin: 0 1.5625vw;'}
    ${props.name === 'play' && (props.act ? 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_pause.png);' : 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_play.png);')}
  `}

  ${(props) => props.kind === 'repeat' && css`
    width: 1.822917vw;
    height: 2.296875vw;
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_repeat_off.png);

    ${props.page && css`
      width: 2.604167vw;
      height: 3.28125vw;
    `}
    
    ${props.act && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/audioPlayer/audio_btn_repeat_on.png);'}
  `}

  ${(props) => props.kind === 'bookMark' && css`
    background: center /contain no-repeat;
    position: absolute;
    z-index: 2;
    top: 1.041667vw;
    left: 87.322917vw;
    width: 2.708333vw;
    height: 3.4375vw;
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/e-book_bookmark_off.png);
    filter: drop-shadow(0 0.104167vw 0.052083vw rgba(0, 0, 0, 0.30));

    ${props.act && 'background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/e-book_bookmark_on.png);'}
  `}

  ${(props) => props.kind === 'page' && css`
    width: 0.546875vw;
    height: 1.015625vw;
    margin: 0 0.546875vw;
    z-index: 2;
  `}

  ${(props) => props.kind === 'go' && css`
    width: 3.125vw;
    height: 100%;
    border-radius: 0 0.260417vw 0.260417vw 0;
    background-color: #fbc442;
    font-size: 0.9375vw;
    font-weight: 600;
  `}

  ${(props) => props.kind === 'ebookMenu' && css`
    position: absolute;
    top: 0;
    left: 100%;
    width: 1.5625vw;
    height: 1.5625vw;
    background: #fbc442 ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/pagenation_pre.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/pagenation_next.png)'} center /0.364583vw 0.677083vw no-repeat;
  `}

  ${(props) => (props.kind === 'tool' && css`
    width: 2.604167vw;
    height: 2.604167vw;
    margin-bottom: 0.520833vw;
    background: center / contain no-repeat;

    ${(props.name === 'select' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_click_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_click.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_click_hover.png);
      }
    `)}

    ${(props.name === 'memo' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_memo_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_memo.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_memo_hover.png);
      }
    `)}

    ${(props.name === 'pen' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_pen_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_pen.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_pen_hover.png);
      }
    `)}

    ${(props.name === 'color' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_color_pen_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_color_pen.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_color_pen_hover.png);
      }
    `)}

    ${(props.name === 'eraser' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_eraser_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_eraser.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_eraser_hover.png);
      }
    `)}

    ${(props.name === 'reset' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_all_eraser_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_all_eraser.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_all_eraser_hover.png);
      }
    `)}

    ${(props.name === 'telescope' && css`
      background-image: ${props.act ? 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_telescope_hover.png)' : 'url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_telescope.png)'};
      &:hover {
        background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/toolKit/toolbox_telescope_hover.png);
      }
    `)}
  `)}

  ${(props) => (props.kind === 'book' && css`
    position: absolute;
    bottom: ${props.bottom}vw;
    left: ${props.left}vw;
    z-index: 2;
    width: 2.34375vw;
    height: 2.552083vw;

    ${props.name === 'answer' && 'background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/btn_answer.png) center / contain no-repeat;'}
    ${props.name === 'track' && 'background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/btn_track.png) center / contain no-repeat;'}
    ${props.name === 'card' && 'background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/btn_card.png) center / contain no-repeat;'}
    ${props.name === 'chant' && 'background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/btn_chant.png) center / contain no-repeat;'}
    ${props.name === 'video' && 'background: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/btn_video.png) center / contain no-repeat;'}

    &:active {
      transform: scale(85%);
    }
  `)}

  ${(props) => props.kind === 'close' && css`
    position: absolute;
    transition: left 0.3s;
    width: 3.125vw;
    height: 3.125vw;
    
    ${props.name === 'video' && css`
      top: 14.529583vw;
      left : ${props.menu ? 74.0875 : 69.85}vw; 
    `}

    ${props.name === 'card' && css`
      top: 8.25125vw;
      left: ${props.menu ? 84.11875 : 79.9}vw;
    `}

    ${props.name === 'audio' && css`
      top: 8.28125vw;
      left: ${props.menu ? 89.21875 : 85}vw;
    `}
    
    ${props.name === 'game' && css`
      top: ${props.book === 1 ? 5.729167 : 5.37}vw;
      left : ${props.book === 1 ? (props.menu ? 87.916667 : 83.697917) : (props.menu ? 88.43875 : 84.175)}vw;
    `}
  `}
  
  ${(props) => props.kind === 'play' && css`
    background: center /contain no-repeat;
    
    ${props.act ? css` 
      width: 2.041667vw;
      height: 2.041667vw;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/videoPlayer/chant_btn_pause.png);
    ` : css`
      width: 1.625vw;
      height: 1.875vw;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/videoPlayer/chant_btn_play.png);
    `}
  `}

  ${(props) => props.kind === 'fullscreen' && css`
    background: center /contain no-repeat;
    width: 1.825vw;
    height: 1.825vw;
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/videoPlayer/icon.png);
  `}

  ${(props) => props.kind === 'slide' && css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2.296875vw;
    height: 3.828125vw;

    ${props.name === 'prev' && css`
      left : 0;
      z-index: 1;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/flashCard/fc_prev_on.png);
    `}

    ${props.name === 'next' && css`
      right: 0;
      background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/flashCard/fc_next_on.png);
    `}
  `}

  ${(props) => props.kind === 'memoClose' && css`
    position: absolute;
    top: 0.78125vw;
    right: 1.458333vw;
    width: 0.78125vw;
    height: 0.78125vw;
  `}
`;

export const Button = ({
  ...props
}:ButtonProps) => {
  const book = useSelector((state : RootState) => state.book);
  const menu = useSelector((state : RootState) => state.menu);

  return (
    <ButtonTag
      kind={props.kind}
      name={props.name}
      animal={props.animal}
      page={props.page}
      act={props.act}
      menu={menu.act}
      book={book.current_book}
      bottom={props.bottom}
      left={props.left}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
    >
      {props.children}
    </ButtonTag>
  )
};