import { AudioTemplate } from "../../_templates/AudioTemplate/audioTemplate";

export interface AudioProps {
  
};

export const Audio = ({...props}: AudioProps) => {
  return (
    <AudioTemplate />
  )
};