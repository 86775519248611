import styled, { css } from "styled-components";

export interface HeadingProps {
  level : number,
  kind? : string,
  children : React.ReactNode,
};

const H1Tag = styled.h1<HeadingProps>`
  ${(props) => (props.kind === 'aside' && css`
    font-size: 1.25vw;
    color: #fdce00;
    cursor: default;
  `)}

  ${(props) => props.kind === 'tool' && css`
    font-size: 1.25vw;
    color: #fdce00;
    cursor: default;
    grid-column: span 2;
  `}
  
  ${(props) => props.kind === 'resource' && css`
    font-size: 4.166667vw;
    font-weight: 800;
    letter-spacing: -0.052083vw;
    color: #5493fc;
  `}
`;

export const Heading = ({...props}: HeadingProps) => {
  switch (props.level) {
    case 1:
      return <H1Tag kind={props.kind} level={props.level}>{props.children}</H1Tag>
    case 2:
      return <h2>{props.children}</h2>;
    case 3:
      return <h3>{props.children}</h3>;
    case 4:
      return <h4>{props.children}</h4>;
    case 5:
      return <h5>{props.children}</h5>;
    case 6:
      return <h6>{props.children}</h6>;
    default:
      return <></>
  }
};