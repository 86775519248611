import { useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useDispatch } from "react-redux";
import styled, { css } from 'styled-components';
import { setDice, setDiceAct } from '../../../store/GameSlice';
import { Dice } from '../../_atoms/Dice/dice';

export interface GameWrapProps {

};

export interface PieceProps {
  pos : {x : number, y : number},
  rabbit? : boolean,
  fox? : boolean,
  sheep? : boolean,
  kind : string,
  onMouseEnter : () => void,
  onMouseLeave : () => void,
};

export interface InfoProps {
  act : boolean,
  onClick? : () => void,
};

const Info = styled.div<InfoProps>`
  background: center /contain no-repeat;
  filter: drop-shadow(0 0 0.260417vw rgba(0, 0, 0, 0.15));
  position: absolute;
  top: 0.78125vw;
  right: 0.78125vw;
  z-index: 2;
  width: 2.447917vw;
  height: 2.447917vw;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/btn_how_off.png);
  cursor: pointer;

  ${(props) => props.act && css`
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/btn_how_on.png);
  `}
`;

const InfoDetail = styled.span<InfoProps>`
  background: center /contain no-repeat;
  filter: drop-shadow(0 0 0.260417vw rgba(0, 0, 0, 0.15));
  display: none;
  position: absolute;
  z-index: 1;
  top: 2.083333vw;
  right: 2.083333vw;
  width: 23.072917vw;
  height: 7.5vw;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/game3_how.png);

  ${(props) => props.act && css`
    display: block;
  `}
`;

const Piece = styled.div<PieceProps>`
  display : block;
  background: center /contain no-repeat;
  filter: drop-shadow(0 0 0.15625vw rgba(0, 0, 0, 0.4));
  position: absolute;
  bottom: 2.5%;
  z-index: 0;
  
  ${(props) => props.kind === 'rabbit' && !props.rabbit && css`
    animation: rabbit 1s ease-in-out infinite;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.51, 1);

    @keyframes rabbit {
      0% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }

      50% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(95%);
      }

      100% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }
    }
  `}
    
  ${(props) => props.kind === 'fox' && !props.fox && css`
    animation: fox 1s ease-in-out infinite;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.51, 1);
    
    @keyframes fox {
      0% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }

      50% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(95%);
      }

      100% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }
    }
  `}
  
  ${(props) => props.kind === 'sheep' && !props.sheep && css`
    animation: sheep 1s ease-in-out infinite;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.51, 1);
    
    @keyframes sheep {
      0% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }

      50% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(95%);
      }

      100% {
        transform: translate(${props.pos.x}px, ${props.pos.y}px) scale(100%);
      }
    }
  `}

  &:hover {
    cursor: move;
    animation: unset;
  }

  &:active {
    transform: scale(120%);
  }

  ${(props) => {
    switch (props.kind) {
      case 'rabbit':
        return css`
          left: 3%;
          width: 4.739583vw;
          height: 7.708333vw;
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/game_rabbit.png);
        `
      case 'fox':
        return css`
          left: calc(3% + 4.739583vw);
          width: 4.739583vw;
          height: 6.822917vw;
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/game_fox.png);
        `
      case 'sheep':
        return css`
          left: calc(3% + 9.479167vw);
          width: 5.260417vw;
          height: 6.510417vw;
          background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/game/game_sheep.png);
        `
      default:
        break;
    }
  }}
`;

export const GameWrap = ({...props}: GameWrapProps) => {
  const dispatch = useDispatch();

  const [rabbit, setRabbit] = useState(false);
  const [fox, setFox] = useState(false);
  const [sheep, setSheep] = useState(false);

  const [rabbitPos, setRabbitPos] = useState({x : 0, y: 0});
  const [foxPos, setFoxPos] = useState({x : 0, y: 0});
  const [sheepPos, setSheepPos] = useState({x : 0, y: 0});

  const [act, setAct] = useState(false);

  const rabbitHandleDrag = (e: DraggableEvent, data: DraggableData) => {
    setRabbitPos({ x: data.x, y: data.y });
  };
  
  const foxHandleDrag = (e: DraggableEvent, data: DraggableData) => {
    setFoxPos({ x: data.x, y: data.y });
  };

  const sheepHandleDrag = (e: DraggableEvent, data: DraggableData) => {
    setSheepPos({ x: data.x, y: data.y });
  };

  const onDiceClick = () => {
    dispatch(setDice(Math.floor(Math.random() * (6 - 1) + 1)));
    dispatch(setDiceAct(true));
  };

  return (
    <>
      <Dice onClick={() => onDiceClick()}/>

      <Info onClick={() => setAct(!act)} act={act}/>
      <InfoDetail act={act}/>

      <Draggable bounds='parent' position={rabbitPos} onDrag={rabbitHandleDrag}>
        <Piece pos={rabbitPos} rabbit={rabbit} kind="rabbit" onMouseEnter={() => setRabbit(true)} onMouseLeave={() => setRabbit(false)} />
      </Draggable>
      <Draggable bounds='parent' position={foxPos} onDrag={foxHandleDrag}> 
        <Piece pos={foxPos} fox={fox} kind="fox" onMouseEnter={() => setFox(true)} onMouseLeave={() => setFox(false)} />
      </Draggable>
      <Draggable bounds='parent' position={sheepPos} onDrag={sheepHandleDrag}>
        <Piece pos={sheepPos} sheep={sheep} kind="sheep" onMouseEnter={() => setSheep(true)} onMouseLeave={() => setSheep(false)}/>
      </Draggable>
    </>
  )
};