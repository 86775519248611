import { Fragment } from "react";
import { Route, Routes } from 'react-router';
import { GlobalStyle } from "./asset/styles/GlobalStyle";
import { IframeGlobalStyle } from "./asset/styles/IframeGlobalStyle";
import { Iframe } from "./components/_atoms/Iframe/iframe";
import { BackgroundMusic } from "./components/_molecules/BackgroundMusic/backgroundMusic";
import { ButtonMusic } from "./components/_molecules/ButtonMusic/buttonMusic";
import { Audio } from "./components/pages/Audio/audio";
import { Ebook } from "./components/pages/Ebook/ebook";
import { Main } from "./components/pages/Main/main";
import { Menu } from "./components/pages/Menu/menu";
import { Resources } from "./components/pages/Resources/resources";

function App() {
  return (
    <Fragment>
      <GlobalStyle />
      <Iframe>
        <IframeGlobalStyle />
        <Routes>
          <Route element={<ButtonMusic />}>
            <Route element={<BackgroundMusic/>}>
              <Route path="/" element={<Main/>} />
              <Route path="/menu" element={<Menu/>} />
            </Route>
            
            <Route path="/audio" element={<Audio />} />
            <Route path="/ebook" element={<Ebook />} />
            <Route path="/resources" element={<Resources />} />
          </Route>
        </Routes>
      </Iframe>
    </Fragment>
  );
}

export default App;