import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setAudioCurrentRange, setAudioCurrentTime } from '../../../store/AudioSlice';
import { setVideoChangeRange, setVideoCurrentRange } from '../../../store/VideoSlice';
import { Input } from "../../_atoms/Input/input";

export interface ProgressBarProps {
  kind : string,
  page : boolean,
  player? : H5AudioPlayer | null
};

export const ProgressBar = ({...props}: ProgressBarProps) => {
  const audio = useSelector((state : RootState) => state.audio);
  const video = useSelector((state : RootState) => state.video);
  const dispatch = useDispatch();

  return (
    <>
      <Input
        type="range"
        name="progress"
        min={0}
        max={100}
        step={1}
        video={props.kind === 'video' ? true : false}
        value={props.kind === 'audio' ? (isNaN(audio.current_range) ? 0 : audio.current_range) : (isNaN(video.current_range) ? 0 : video.current_range)}
        page={props.page}
        player={props.player}
        onChange={(e : React.ChangeEvent<HTMLInputElement>) => {
          if (props.player instanceof H5AudioPlayer) {
            dispatch(setAudioCurrentRange(e.target.value));
            dispatch(setAudioCurrentTime(props.player?.audio.current?.duration && props.player.audio.current.duration * parseInt(e.target.value) / 100));
            if (props.player?.audio.current?.currentTime) {
              props.player.audio.current.currentTime = props.player?.audio.current?.duration && props.player.audio.current.duration * parseInt(e.target.value) / 100;
            }
          } else {
            dispatch(setVideoCurrentRange(e.target.value));
            dispatch(setVideoChangeRange(e.target.value));
          }
        }}
      />
    </>
  )
};