import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from "../../../store";
import { setCurrentPage } from '../../../store/BookSlice';
import { Button } from '../../_atoms/Button/button';
import { Input } from "../../_atoms/Input/input";
import { ImageButton } from "../ImageButton/imageButton";

export interface EbookPageProps {
  
};

const Wrap = styled.div`
  position: absolute;
  top: 51.25vw;
  left: 79.739583vw;
  width: 10.9375vw;
  height: 3.125vw;
  border-radius: 0.260417vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

const PageBox = styled.div`
  width: 7.8125vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EbookPage = ({...props}: EbookPageProps) => {
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const [value, setValue] = useState(book.current_page);

  useEffect(() => {
    setValue(book.current_page);
  }, [book.current_page]);

  const onPrevButton = () => {
    const index = book.bookInfo.page.findIndex((page : string) => page === book.current_page);
    dispatch(setCurrentPage(index !== 0 ? book.bookInfo.page[index - 1] : book.current_page));
  };
  
  const onNextButton = () => {
    const index = book.bookInfo.page.findIndex((page : string) => page === book.current_page);
    dispatch(setCurrentPage(index !== book.bookInfo.page.length - 1 ? book.bookInfo.page[index + 1] : book.current_page));
  };

  const onPageChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onPageSearch = (e : React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      const pages = book.bookInfo.page.filter((page : string) => page.includes(e.currentTarget.value));
      pages.length > 0 ? dispatch(setCurrentPage(pages[0])) : setValue(book.current_page);
    }
  };

  const onPageGo = () => {
    const pages = book.bookInfo.page.filter((page : string) => page.includes(value));

    if (pages.length > 0) {
      dispatch(setCurrentPage(pages[0]));
      setValue(pages[0]);
    } else {
      setValue(book.current_page)
    }
  };

  return (
    <Wrap>
      <PageBox>
        <ImageButton
          kind='page'
          src='https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/pagenation_pre.png'
          alt='pre page'
          onClick={() => onPrevButton()}
        />
        <Input
          type="text"
          name="page"
          value={value}
          onChange={(e : React.ChangeEvent<HTMLInputElement>) => onPageChange(e)}
          onKeyDown={(e : React.KeyboardEvent<HTMLInputElement>) => onPageSearch(e)}
        />
        <ImageButton 
          kind='page'
          src='https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/ebook/pagenation_next.png'
          alt='next'
          onClick={() => onNextButton()}
        />
      </PageBox>
      <Button kind="go" onClick={() => onPageGo()}>Go</Button>
    </Wrap>
  )
};