import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ContentBookPath } from "../../../asset/utils/Constant";
import { RootState } from "../../../store";
import { setLoading } from "../../../store/CommonSlice";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";

export interface ResourcesWrapProps {
  
};

const BtnWrap = styled.div`
  display: flex;
  grid-column-gap: 0.78125vw;
  margin-top: 1.5625vw;
  margin-bottom: 20.833333vw; 
`;

export const ResourcesWrap = ({...props}: ResourcesWrapProps) => {
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();
  
  const onFileDownload = (value : string) => {
    const path = ContentBookPath + book.current_book + "/Learning_Resources/" + value + '.zip';

    fetch(path, {method : 'GET'}).then((res) => {
      dispatch(setLoading(true));
      return res.blob();
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = value;

      document.body.appendChild(a);
      a.click();

      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);

      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000)

      a.remove();
    }).catch((err) => {
      console.error('err : ', err);
    });
  };

  return (
    <BtnWrap>
      <ImageButton 
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Answer_Key.png"
        alt="Answer_Key"
        onClick={() => onFileDownload('Answer_Key')}
      />
      <ImageButton 
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Flashcard.png"
        alt="Flashcard"
        onClick={() => onFileDownload('Flash_Card')}
      />
      <ImageButton 
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Lesson_Plna.png"
        alt="Lesson_Plna"
        onClick={() => onFileDownload('Lesson_Plan')}
      />
      <ImageButton
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Mp3_File.png"
        alt="Mp3_File"
        onClick={() => onFileDownload('Mp3_file')}
      />
      <ImageButton 
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Syllabus.png"
        alt="Syllabus"
        onClick={() => onFileDownload('Syllabus')}
      />
      <ImageButton 
        kind="resource"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/LearningResources/Test.png"
        alt="Test"
        onClick={() => onFileDownload('Test')}
      />
    </BtnWrap>
  )
};