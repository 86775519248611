import styled from "styled-components";

export interface SectionProps {
  act : boolean,
  children : React.ReactNode,
};

const SectionTag = styled.section<SectionProps>`
  position: absolute;
  top: 0;
  width: 108.4375vw;
  transition: left 0.3s;
  z-index: 0;
  height : 100%;

  ${(props) => props.act ? 'left : 0;' : 'left: -4.21875vw;'}
`;

export const Section = ({...props}: SectionProps) => {
  return (
    <SectionTag act={props.act}>{props.children}</SectionTag>
  )
};