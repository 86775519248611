export const CardWordList = [
  [
    {
      unit : 1,
      name : "Unit 1",
      word : [
        ["apple", "ant", "alligator"],
        ["bear", "book", "banana"],
        ["cat", "corn", "car"]
      ]
        },  
    {
      unit : 2,
      name : "Unit 2",
      word : [
        ["dog", "donut", "drum"],
        ["elephant", "egg", "elbow"],
        ["fish", "frog", "fork"]
      ],  
    },  
    {
      unit : 3,
      name : "Unit 3",
      word : [
        ["goat", "gift", "girl"],
        ["hat", "hippo", "house"],
        ["iguana", "ink", "igloo"]
      ],
    },  
    {
      unit : 5,
      name : "Unit 5",
      word : [
        ["jam", "jet", "juice"],
        ["king", "key", "kiwi"],
        ["lion", "leaf", "lemon"]
      ],
    },  
    {
      unit : 6,
      name : "Unit 6",
      word : [
        ["monkey", "melon", "milk"],
        ["nut", "nail", "notebook"],
        ["ox", "ostrich", "octopus"]
      ],
    },  
    {
      unit : 7,
      name : "Unit 7",
      word : [
        ["panda", "pencil", "pie"],
        ["queen", "quiet", "question"],
        ["rabbit", "run", "robot"]
      ],
    },  
    {
      unit : 9,
      name : "Unit 9",
      word : [
        ["sit", "sun", "sock"],
        ["tiger", "tent", "turtle"],
        ["umbrella", "under", "up"]
      ],
    },  
    {
      unit : 10,
      name : "Unit 10",
      word : [
        ["vet", "van", "violin"],
        ["watch", "web", "window"],
        ["fox", "box", "six"]
      ],
    },  
    {
      unit : 11,
      name : "Unit 11", 
      word : [
        ["yacht", "yogurt", "yo-yo"],
        ["zebra", "zoo", "zipper"]
      ],
    }
  ],
  [
    {
      unit : 1,
      name : "Unit 1", 
      word : [
        ["bat", "mat", "rat"],
        ["fan", "can"],
        ["map", "nap"]
      ],
    },  
    {
      unit : 2,
      name : "Unit 2", 
      word : [
        ["pet", "net", "wet"],
        ["bed", "red"],
        ["hen", "pen"]
      ],
    },  
    {
      unit : 3,
      name : "Unit 3", 
      word : [
        ["lip", "zip", "hip"],
        ["dig", "wig"],
        ["fin", "bin"]
      ],
    },  
    {
      unit : 5,
      name : "Unit 5", 
      word : [
        ["pot", "hot", "dot"],
        ["mop", "pop"],
        ["fox", "box"]
      ],
    },  
    {
      unit : 6,
      name : "Unit 6", 
      word : [
        ["hut", "nut", "cut"],
        ["mug", "bug"],
        ["fun", "run"]
      ],
    },  
    {
      unit : 7,
      name : "Unit 7", 
      word : [
        ["cake", "rake", "bake"],
        ["game", "name"],
        ["wave", "cave"]
      ],
    },  
    {
      unit : 9,
      name : "Unit 9", 
      word : [
        ["ride", "hide", "wide"],
        ["hike", "bike"],
        ["dive", "five"]
      ],
    },  
    {
      unit : 10,
      name : "Unit 10", 
      word : [
        ["hole", "mole", "pole"],
        ["dome", "home"],
        ["bone", "cone"]
      ],
    },  
    {
      unit : 11,
      name : "Unit 11", 
      word : [
        ["dune", "tune", "june"],
        ["cute", "mute"],
        ["tube", "cube"]
      ],
    },
  ],
  [
    {
      unit : 1,
      name : "Unit 1", 
      word : [
        ["black", "block", "blanket"],
        ["clap", "clock"],
        ["flag", "fly"]
      ],
    },  
    {
      unit : 2,
      name : "Unit 2", 
      word : [
        ["green", "grass", "grape"],
        ["dress", "dragon"],
        ["truck", "tree"]
      ],
    },  
    {
      unit : 3,
      name : "Unit 3", 
      word : [
        ["snake", "snack", "snore"],
        ["stop", "stone"],
        ["swim", "swing"]
      ],
    },  
    {
      unit : 4,
      name : "Unit 4", 
      word : [
        ["chick", "chin", "cheese"],
        ["ship", "shell"],
        ["think", "three"]
      ],
    },  
    {
      unit : 5,
      name : "Unit 5", 
      word : [
        ["bench", "catch", "lunch"],
        ["fish", "brush"],
        ["bath", "teeth"]
      ],
    },  
    {
      unit : 7,
      name : "Unit 7", 
      word : [
        ["snail", "tail", "rain", "train"],
        ["gray", "clay", "spray","crayon"]
      ],
    },  
    {
      unit : 8,
      name : "Unit 8", 
      word : [
        ["seal", "leaf", "beach", "meat"],
        ["bee", "sheep", "seed", "feet"]
      ],
    },  
    {
      unit : 9,
      name : "Unit 9", 
      word : [
        ["boat", "road", "goat", "coat"],
        ["snow", "window", "blow", "yellow"]
      ],
    },  
    {
      unit : 10,
      name : "Unit 10", 
      word : [
        ["cloud", "house", "blouse", "mouse"],
        ["cow", "crown", "gown", "down"]
      ],
    },  
    {
      unit : 11,
      name : "Unit 11", 
      word : [
        ["coin", "boil", "oil", "point"],
        ["boy", "toy", "joy", "soybean"]
      ],
    },    
  ],
];