import { useRef } from "react";
import H5AudioPlayer from 'react-h5-audio-player';
import { Outlet } from "react-router";
import styled from "styled-components";

export interface BackgroundMusicProps {
  
};

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const BackgroundMusic = ({...props}: BackgroundMusicProps) => {
  const player = useRef<H5AudioPlayer>(null);

  return (
    <>
      <AudioPlayer
        ref={player}
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/mp3/homebgm_Bike_Rides.mp3"
        autoPlay={true}
      />
      <Outlet />
    </>
  )
};
