import styled, { css } from "styled-components";

export interface TextProps {
  kind : string,
  tabIndex : number ,
  maxLength : number,
  children : React.ReactNode,
  onChange : (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
};

const TextArea = styled.textarea<TextProps>`
  ${(props) => props.kind === 'memo' && css`
    position: absolute;
    top: 2.083333vw;
    left: 0.520833vw;
    width: 15.520833vw;
    height: 14.6875vw;
    padding: 0 0.364583vw 0.78125vw 0.78125vw;
    border: 0px;
    outline: medium;
    background: transparent;
    font-size: 1.041667vw;
    resize: none;
    overflow-y: scroll;

    &:focus-visible {
      outline-offset: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fdce00;
    }
  `}
`;

export const Text = ({...props}: TextProps) => {
  return (
    <TextArea 
      kind={props.kind} 
      tabIndex={props.tabIndex} 
      maxLength={props.maxLength}
      onChange={props.onChange}
    >
      {props.children}
    </TextArea>
  )
};