
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import { CardWordList } from '../../../asset/utils/CardWordList';
import { ContentBookPath } from '../../../asset/utils/Constant';
import { RootState } from "../../../store";
import { setSlide } from '../../../store/CardSlice';
import { Button } from '../../_atoms/Button/button';
import { Item } from '../../_atoms/Item/item';
import { List } from '../../_atoms/List/list';
import { FlashCardList } from '../../_molecules/FlashCardList/flashCardList';

export interface FlashCardWrapProps {
  
};

const StyledSlider = styled(Slider)`
  width : 100%;

  .slick-list {
    width : 100%;
    height: 100%;
  }

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    width : 100%;
    height : 100%;

    div {
      width : 100%;
      height : 100%;
    }
  }

  .slick-prev.slick-disabled {
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/flashCard/fc_prev_off.png);
  }
  
  .slick-next.slick-disabled {
    background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/flashCard/fc_next_off.png);
  }

  .slick-dots .slick-active li {
    background-color: #888;
  }
`;

export const FlashCardWrap = ({...props}: FlashCardWrapProps) => {
  const book = useSelector((state : RootState) => state.book);
  const card = useSelector((state : RootState) => state.card);
  const dispatch = useDispatch();

  const slider = useRef<Slider>(null);

  useEffect(() => {
    const slideArray = (CardWordList[book.current_book - 1][card.unit].word).map((slide, idx) => {
      return (
        slide.map((word, i) => ({
          word : word,
          act : false,
          choice : false,
          back : false,
          reset : false,
          src : ContentBookPath + (book.current_book) + '/flashCard/b' + (book.current_book) + '-unit' + CardWordList[book.current_book - 1][card.unit].unit + '-' + word + '_optimized.png',
          wsrc : ContentBookPath + (book.current_book) + '/flashCard/b' + (book.current_book) + '-unit' + CardWordList[book.current_book - 1][card.unit].unit + '-w-' + word + '_optimized.png',
        }))
      )
    });

    dispatch(setSlide(slideArray));

    slider.current?.slickGoTo(0);
  }, [card.unit]);

  const settings = {
    dots : true,
    infinite : false,
    speed : 500,
    slidesToShow : 1,
    slidesToScroll : 1,
    nextArrow : <Button kind="slide" name="next" disabled={card.word_play} />,
    prevArrow : <Button kind="slide" name="prev" disabled={card.word_play} />,
    appendDots : (dots : React.ReactNode) => (
      <List order={false} kind="dots">
        {dots}
      </List>
    ), 
    customPaging : (idx : number) => (
      <Item kind="dots"></Item>
    )
  };

  return (
    <StyledSlider {...settings} ref={slider}>
      {card.slide && card.slide.map((card, idx) => {
        return (
          <FlashCardList card={card} idx={idx}/>
        );
      })}
    </StyledSlider>
  )
};

