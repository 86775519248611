import { Button } from "../../_atoms/Button/button";
import { Image } from "../../_atoms/Image/image";

export interface ImageButtonProps {
  kind : string,
  src : string,
  alt : string,
  name? : string,
  onClick : () => void,
  onMouseOver? : () => void,
};

export const ImageButton = ({...props}: ImageButtonProps) => {
  return (
    <Button
      kind={props.kind}
      name={props.name}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
    >
      <Image
        src={props.src}
        alt={props.alt}
        kind={props.kind}
      />
    </Button>
  )
};