import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CardWordList } from "../../../asset/utils/CardWordList";
import { ContentBookPath, ContentPath } from "../../../asset/utils/Constant";
import { RootState } from "../../../store";
import { setCardUnit } from "../../../store/CardSlice";
import { setPopCard, setPopVideo } from "../../../store/CommonSlice";
import { setVideoSrc } from "../../../store/VideoSlice";
import { Audio } from "../../_atoms/Audio/audio";
import { Button } from "../../_atoms/Button/button";
import { Image } from "../../_atoms/Image/image";

export interface EbookProps {

};

type ArrayProps = {act : boolean, path : string, posX : string, posY : string}[];

export const Ebook = ({...props}: EbookProps) => {
  const book = useSelector((state : RootState) => state.book);
  const common = useSelector((state : RootState) => state.common);
  const menu = useSelector((state : RootState) => state.menu);
  const dispatch = useDispatch();
  
  const [src, setSrc] = useState('');
  const [answer, setAnswer] = useState<ArrayProps>();
  const [track, setTrack] = useState<ArrayProps>();
  const [card, setCard] = useState<ArrayProps>();
  const [chant, setChant] = useState<ArrayProps>();
  const [video, setVideo] = useState<ArrayProps>();

  useEffect(() => {
    if (book.bookInfo[book.current_page]) {
      const currentPageInfo = book.bookInfo[book.current_page];

      setSrc(currentPageInfo.path);
      setAnswer(initializeArray(currentPageInfo.answer));
      setTrack(initializeArray(currentPageInfo.track));
      setCard(currentPageInfo.card);
      setChant(initializeArray(currentPageInfo.chant));
      setVideo(currentPageInfo.video);
    }

    if (common.pop_audio || common.pop_video || common.pop_card) {
      setAnswer(initializeArray(book.bookInfo[book.current_page].answer));
      setTrack(initializeArray(book.bookInfo[book.current_page].track));
      setCard(book.bookInfo[book.current_page].card);
      setChant(initializeArray(book.bookInfo[book.current_page].chant));
    }
  }, [menu.unit, book.current_page, book.bookInfo, common.pop_video, common.pop_audio, common.pop_card]);

  const initializeArray = (array : any) => {
    return array && array.map((item : any) => ({ ...item, act: false }));
  };

  const onAnswer = (idx : number) => {
    if (answer) {
      answer[idx].act = !answer[idx].act;
      setAnswer([...answer]);
    }
  };

  const onTrack = (idx : number) => {
    if (track) {
      const index = track.findIndex((tra) => tra.act === true);
      if (index !== -1 && index !== idx) {
        track[index].act = false;
        setTrack([...track]);
      }

      const chantArray = book.bookInfo[book.current_page].chant && book.bookInfo[book.current_page].chant.map((cha : ArrayProps) => ({
        ...cha, act : false
      }));

      setChant(chantArray);
      
      track[idx].act = !track[idx].act;
      setTrack([...track]);
    }
  };

  const onChant = (idx : number) => {
    const trackArray = book.bookInfo[book.current_page].track && book.bookInfo[book.current_page].track.map((tra : ArrayProps) => ({
      ...tra, act : false
    }));

    setTrack(trackArray);

    if (chant) {
      const index = chant.findIndex((cha) => cha.act === true);
      if (index !== -1 && index !== idx) {
        chant[index].act = false;
        setChant([...chant]);
      }
      
      chant[idx].act = !chant[idx].act;
      setChant([...chant]);
    }
  };
  
  const onVideo = (idx : number) => {
    const trackArray = book.bookInfo[book.current_page].track && book.bookInfo[book.current_page].track.map((tra : ArrayProps) => ({
      ...tra, act : false
    }));
    setTrack(trackArray);

    const chantArray = (book.bookInfo)[book.current_page].chant && (book.bookInfo)[book.current_page].chant.map((cha : ArrayProps) => ({
      ...cha, act : false
    }));
    setChant(chantArray);

    video && dispatch(setVideoSrc(video[idx].path));
    dispatch(setPopVideo(!common.pop_video));
  };

  const onCard = (idx : number) => {
    const trackArray = book.bookInfo[book.current_page].track && book.bookInfo[book.current_page].track.map((tra : ArrayProps) => ({
      ...tra, act : false
    }));
    setTrack(trackArray);

    const chantArray = book.bookInfo[book.current_page].chant && book.bookInfo[book.current_page].chant.map((cha : ArrayProps)=> ({
      ...cha, act : false
    }));

    setChant(chantArray);

    dispatch(setPopCard(!common.pop_card));
    card && dispatch(setCardUnit(CardWordList[book.current_book - 1].findIndex((car) => car.name === 'Unit ' + card[idx].path)));
  };

  return (
    <>
      <Image
        kind='ebook'
        src={ContentBookPath + book.current_book + '/' + src + '.png'}
        alt={src}
      />

      {/* answer */}
      {answer && (answer.map((ans : {
        act : boolean,
        path : string,
        posX : string,
        posY : string,
      }, idx) => {
        return (
          <>
            <Button 
              kind="book" 
              name='answer'
              bottom={(parseInt(ans.posY) - 19) / 1920 * 100} 
              left={(parseInt(ans.posX) - 21) / 1920 * 100}
              onClick={() => onAnswer(idx)}
            />
            <Image
              src={ContentBookPath + book.current_book + '/' + ans.path + '.png'}
              alt={ans.path}
              act={answer[idx].act}
              kind='book'
            />
          </>
        )
      }))}

      {/* track */}
      {track && (track.map((tra, idx) => {
        return (
          <>
            <Button 
              kind="book" 
              name='track' 
              bottom={(parseInt(tra.posY) - 19) / 1920 * 100} 
              left={(parseInt(tra.posX) - 21) / 1920 * 100}
              onClick={() => onTrack(idx)}
            />
            {track[idx].act && (
              <Audio
                autoplay='autoplay'
                src={ContentPath + tra.path + '.mp3'}
                act={track[idx].act}
                bottom={(parseInt(tra.posY) - 19 + 64) / 1920 * 100} 
                left={(parseInt(tra.posX) - 21 + 45 * 0.5) / 1920 * 100}
              />
            )}
          </>
        )
      }))}
      
      {/* card */}
      {card && (card.map((car, idx) => {
        return (
          <>
            <Button 
              kind="book" 
              name='card' 
              bottom={(parseInt(car.posY) - 19) / 1920 * 100}
              left={(parseInt(car.posX) - 21) / 1920 * 100}
              onClick={() => onCard(idx)}
            />
          </>
        );
      }))}

      {/* chant */}
      {chant && (chant.map((cha, idx) => {
        return (
          <>
            <Button 
              kind="book" 
              name='chant' 
              bottom={(parseInt(cha.posY) - 19) / 1920 * 100} 
              left={(parseInt(cha.posX) - 21) / 1920 * 100}
              onClick={() => onChant(idx)}
            />
            {chant[idx].act && (
              <Audio
                autoplay={"autoplay"}
                src={ContentPath + cha.path + '.mp3'}
                act={chant[idx].act}
                bottom={(parseInt(cha.posY) - 19 + 64) / 1920 * 100} 
                left={(parseInt(cha.posX) - 21 + 45 * 0.5) / 1920 * 100}
              />
            )}
          </>
        )
      }))}
      
      {/* video */}
      {video && (video.map((vid, idx) => {
        return (
          <>
            <Button 
              kind="book" 
              name='video' 
              bottom={(parseInt(vid.posY) - 19) / 1920 * 100} 
              left={(parseInt(vid.posX) - 21) / 1920 * 100}
              onClick={() => onVideo(idx)}
            />
          </>
        );
      }))}
    </>
  )
};