import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from "../../../store";
import { closeMenu } from '../../../store/MenuSlice';
import { Main } from "../../_atoms/Main/main";

export interface FrameProps {
  kind : string;
  children : React.ReactNode;
};

const Wrap = styled.div`
  width : 100vw;
  height : 56.25vw;
`;

export const Frame = ({...props}: FrameProps) => {
  const common = useSelector((state : RootState) => state.common);
  const dispatch = useDispatch();

  const handleCloseMenu = (e : MouseEvent) => {
    const target = e.target as HTMLElement;
    const kind = target.attributes.getNamedItem('kind')?.nodeValue;

    if (target.tagName !== 'A' && kind !== 'aside' && kind !== 'asideIn' && kind !== 'animation' && (kind === 'animation' && !common.pop_video)) {
      dispatch(closeMenu());
    }
  };

  useEffect(() => {
    window.frames[0].document?.addEventListener('mousedown', handleCloseMenu);
    return () => {
      window.frames[0].document?.addEventListener('mousedown', handleCloseMenu);
    };
  });

  return (
    <Wrap>
      <Main kind={props.kind} book={1}>
        {props.children}
      </Main>
    </Wrap>
  )
};