export const SlideMenuList = [
  [
    {
      depth1 : 'Book List',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3']]
    },
    {
      depth1 : 'Resource',
      depth2 : ['Audio Track', 'Animation', 'Flashcard', 'Game'],
      depth3 : [null, ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 9', 'Unit 10', 'Unit 11', 'Readers'], ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 9', 'Unit 10', 'Unit 11'], null],
      depth4 : [['A', 'B', 'C', 'A,B,C', 'Story'], ['D', 'E', 'F', 'D,E,F', 'Story'], ['G', 'H', 'I', 'G,H,I', 'Story'], ['J', 'K', 'L', 'J,K,L', 'Story'], ['M', 'N', 'O', 'M,N,O', 'Story'], ['P', 'Q', 'R', 'P,Q,R', 'Story'], ['S', 'T', 'U', 'S,T,U', 'Story'], ['V', 'W', 'X', 'V,W,X', 'Story'], ['Y', 'Z', 'Y,Z', 'Story'], ['A Birthday Party', 'The Key and the Notebook', 'Hide and Seek']]
    },
    {
      depth1 : 'Bookmark',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [[], [], []],
    }
  ],
  [
    {
      depth1 : 'Book List',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3']]
    },
    {
      depth1 : 'Resource',
      depth2 : ['Audio Track', 'Animation', 'Flashcard', 'Game'],
      depth3 : [null, ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 9', 'Unit 10', 'Unit 11', 'Readers'], ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 9', 'Unit 10', 'Unit 11'], null],
      depth4 : [['-at', '-an,-ap', '-at,-an,-ap', 'Story'], ['-et', '-ed,-en', '-et,-ed,-en', 'Story'], ['-ip', '-ig,-in', '-ip,-ig,-in', 'Story'], ['-ot', '-op,-ox', '-ot,-op,-ox', 'Story'], ['-ut', '-ug,-un', '-ut,-ug,-un', 'Story'], ['-ake', '-ame,-ave', '-ake,-ame,-ave', 'Story'], ['-ide', '-ike,-ive', '-ide,-ike,-ive', 'Story'], ['-ole', '-ome,-one', '-ole,-ome,-one', 'Story'], ['-une', '-ute,-ube', '-une,-ute,-ube', 'Story'], ['The Wants to Play!', 'Find the Pop!', 'The Five Cubes']]
    },
    {
      depth1 : 'Bookmark',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [[], [], []],
    }
  ],
  [
    {
      depth1 : 'Book List',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2']]
    },
    {
      depth1 : 'Resource',
      depth2 : ['Audio Track', 'Animation', 'Flashcard', 'Game'],
      depth3 : [null, ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 5', 'Unit 6', 'Unit 7', 'Unit 8', 'Unit 9', 'Unit 10', 'Unit 11', 'Readers'], ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 4', 'Unit 5', 'Unit 7', 'Unit 8', 'Unit 9', 'Unit 10', 'Unit 11'], null],
      depth4 : [['b1-', 'cl-,fl-', 'bl-,cl-,fl-', 'Story'], ['gr-', 'dr-,tr-', 'gr-,dr-,tr-', 'Story'], ['sn-', 'st-,sw-', 'sn-,st-,sw-', 'Story'], ['ch-', 'sh-,th-', 'ch-,sh-,th-', 'Story'], ['-ch', '-sh,-th', '-ch,-sh,-th', 'Story'], ['-ai', '-ay', '-ai,-ay', 'Story'], ['-ea', '-ee', '-ea,-ee', 'Story'], ['-oa', '-ow', '-oa,-ow', 'Story'], ['-ou', '-ow', '-ou,-ow', 'Story'], ['-oi', '-oy', '-oi,-oy', 'Story'], ['A Busy Day', 'Playing in the Rain']]
    },
    {
      depth1 : 'Bookmark',
      depth2 : ['Student Book', 'Work Book', 'Readers'],
      depth3 : [[], [], []],
    }
  ]
];
