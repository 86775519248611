import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import audio from "./store/AudioSlice";
import book from "./store/BookSlice";
import card from "./store/CardSlice";
import common from "./store/CommonSlice";
import game from "./store/GameSlice";
import menu from "./store/MenuSlice";
import tool from "./store/ToolSlice";
import video from "./store/VideoSlice";

const reducers = combineReducers({
  common : persistReducer({
    key : 'common',
    storage,
    blacklist : ['btn_sound', 'pop_audio', 'pop_video', 'pop_card', 'fullscreen', 'loading']
  }, common.reducer),
  book : book.reducer,
  audio: persistReducer({
    key : 'audio',
    storage,
    blacklist : ['play', 'rewind', 'current_unit', 'current_track', 'duration_time', 'current_time', 'current_range',],
  }, audio.reducer),
  video: persistReducer({
    key : 'video',
    storage,
    blacklist : ['play', 'control', 'duration_time', 'current_time', 'current_range', 'change_range', 'video_src'],
  }, video.reducer),
  menu : persistReducer({
    key : 'menu',
    storage,
    blacklist : ['act', 'content_menu', 'unit_menu', 'animation_menu', 'menu', 'content', 'unit', 'animation', 'bookmark']
  }, menu.reducer),
  tool : persistReducer({
    key : 'tool',
    storage,
    blacklist : ['mode', 'tool_act', 'memo_act', 'mask_act', 'clear', 'undo']
  }, tool.reducer),
  card : persistReducer({
    key : 'card',
    storage,
    blacklist : ['unit', 'slide', 'word_play']
  }, card.reducer),
  game : persistReducer({
    key : 'game',
    storage,
    blacklist : ['dice', 'dice_act']
  }, game.reducer),
});

const persistConfig = {
  key : 'root',
  storage,
  blacklist : ['common', 'audio', 'video', 'menu', 'tool', 'card', 'game']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export const store = configureStore({
  reducer : persistedReducer,
  middleware : [thunk]
});
