import { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store";
import { setClear, setUndo } from "../../../store/ToolSlice";

export interface CanvasProps {
  
};

const CanvasTag = styled(CanvasDraw)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Canvas = ({...props}: CanvasProps) => {
  const tool = useSelector((state : RootState) => state.tool);
  const dispatch = useDispatch();

  const canvasRef = useRef<CanvasDraw>(null);

  // 실서비스는 25, 테스트는 26
  const iframe = window.document.all[25] as HTMLIFrameElement;
  const contentWindow = iframe.contentWindow;

  const [width, setWidth] = useState(contentWindow && contentWindow.innerWidth + (contentWindow.innerWidth * 0.084375));
  const [height, setHeight] = useState(contentWindow && contentWindow.innerHeight);

  useEffect(() => {
    window.document.addEventListener('resize', handleResize);
    return () => {
      window.document.addEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    tool.clear && canvasRef?.current?.clear();
    tool.undo && canvasRef?.current?.undo();

    dispatch(setClear(false));
    dispatch(setUndo(false));
  }, [tool.clear, tool.undo]);

  const handleResize = () => {
    if (contentWindow) {
      setWidth(contentWindow.innerWidth + (contentWindow.innerWidth * 0.084375));
      setHeight(contentWindow.innerHeight);
    }
  };

  return (
    <CanvasTag 
      ref={canvasRef}
      hideInterface
      hideGrid
      disabled={tool.mode === 'S' ? true : false}
      canvasWidth={width!}
      canvasHeight={height!}
      brushColor={tool.mode === 'P' ? 'black' : (tool.mode === 'R' ? 'red' : '')}
      brushRadius={4}
      style={{background:'transparent'}}
    />
  )
};