import { EbookTemplate } from "../../_templates/EbookTemplate/ebookTemplate";

export interface EbookProps {
  
};

export const Ebook = ({...props}: EbookProps) => {
  return (
    <EbookTemplate />
  )
};