import { createSlice } from "@reduxjs/toolkit";
import { StudentBook } from "../asset/utils/StudentBook";

interface BookSliceProps {
  current_book : number,
  current_menu : number,
  current_content : number,
  current_unit : number,
  current_animation : number,
  current_page : string,
  bookInfo : any,
  bookmark : {
    book : number, 
    content : number, 
    page : string
  }[],
};

const initialState : BookSliceProps = {
  current_book : 1,
  current_menu : 0,
  current_content : 0,
  current_unit : 0,
  current_animation : 0,
  current_page : '6-7',
  bookInfo : StudentBook[0],
  bookmark : []
};

const book = createSlice({
  name : "book",
  initialState,
  reducers : {
    resetBook(state) {
      state.current_menu = 0;
      state.current_content = 0;
      state.current_unit = 0;
      state.current_animation = 0;
      state.current_page = '6-7';
      state.bookInfo = StudentBook[0];
    },
    setCurrentBook(state, action) {
      state.current_book = action.payload;
    },
    setCurrentMenu(state, action) {
      state.current_menu = action.payload;
    },
    setCurrentContent(state, action) {
      state.current_content = action.payload;
    },
    setCurrentUnit(state, action) {
      state.current_unit = action.payload;
    },
    setCurrentAnimation(state, action) {
      state.current_animation = action.payload;
    },
    setCurrentPage(state, action) {
      state.current_page = action.payload;
    },
    setBookInfo(state, action) {
      state.bookInfo = action.payload;
    },
    setBookmark(state, action) {
      state.bookmark = action.payload;
    },
  }
});

export const {resetBook, setCurrentBook, setCurrentMenu, setCurrentContent, setCurrentUnit, setCurrentAnimation, setCurrentPage, setBookInfo, setBookmark} = book.actions;

export default book;