import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  btn_sound : false,
  pop_audio : false,
  pop_video : false,
  pop_card : false,
  pop_game : false,
  fullscreen : false,
  loading : false,
};

const common = createSlice({
  name : "common",
  initialState,
  reducers : {
    setBtnSound(state, action) {
      state.btn_sound = action.payload;
    },
    closePop(state) {
      state.pop_audio = false;
      state.pop_video = false;
      state.pop_card = false;
      state.pop_game = false;
    },
    setPopAudio(state, action) {
      state.pop_audio = action.payload;
    },
    setPopVideo(state, action) {
      state.pop_video = action.payload;
    },
    setPopCard(state, action) {
      state.pop_card = action.payload;
    },
    setPopGame(state, action) {
      state.pop_game = action.payload;
    },
    setFullscreen(state, action) {
      state.fullscreen = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  }
});

export const {
  setBtnSound,
  closePop, 
  setPopAudio, 
  setPopVideo, 
  setPopCard, 
  setPopGame, 
  setFullscreen,
  setLoading
} = common.actions;

export default common;