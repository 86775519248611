import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode : 'S', // S : 선택툴, P : 연필, R : 빨간펜,
  tool_act : true,
  memo_act : false,
  memo : '',
  mask_act : false,
  clear : false,
  undo : false,
};

const tool = createSlice({
  name : "tool",
  initialState,
  reducers : {
    resetTool: () => initialState,
    setMode(state, action) {
      state.mode = action.payload;
    },
    setToolAct(state, action) {
      state.tool_act = action.payload;
    },
    setMemoAct(state, action) {
      state.memo_act = action.payload;
    },
    setMemo(state, action) {
      state.memo = action.payload;
    },
    setMaskAct(state, action) {
      state.mask_act = action.payload;
    },
    setClear(state, action) {
      state.clear = action.payload;
    },
    setUndo(state, action) {
      state.undo = action.payload;
    }
  }
});

export const { 
  resetTool, 
  setMode, 
  setToolAct, 
  setMemoAct, 
  setMemo, 
  setMaskAct, 
  setClear, 
  setUndo 
} = tool.actions;

export default tool;