import { useSelector } from 'react-redux';
import styled from "styled-components";
import { RootState } from "../../../store";
import { AudioList } from "../../_molecules/AudioList/audioList";

export interface AudioListWrapProps {
  page : boolean,
};

const UnitWrap =styled.div`
  grid-row: span 3;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: -0.260417vw 0 0.520833vw 0 #000;
`;

export const AudioListWrap = ({...props}: AudioListWrapProps) => {
  const state = useSelector((state : RootState) => state.audio);

  return (
    <UnitWrap>
      <AudioList kind="unit" page={props.page} />
      <AudioList kind="track" page={props.page} />
    </UnitWrap>
)
};