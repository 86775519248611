import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { ContentBookPath, Mp4Path } from '../../../asset/utils/Constant';
import { RootState } from "../../../store";
import { setFullscreen } from '../../../store/CommonSlice';
import { setVideoCurrentRange, setVideoCurrentTime, setVideoDurationTime, setVideoPlay } from '../../../store/VideoSlice';

export interface VideoProps {
  
};

const VideoTag = styled.video`
  width: 40vw;
  height: 22.5vw;
`;

export const Video = ({...props} : VideoProps) => {
  const video = useSelector((state : RootState) => state.video);
  const common = useSelector((state : RootState) => state.common);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();

  const player = useRef<HTMLVideoElement>(null);

  document.addEventListener("fullscreenchange", () => {
    if (!document.fullscreenElement && !common.fullscreen) {
      dispatch(setFullscreen(false));
    }
  });

  useEffect(() => {
    player.current?.load();

    dispatch(setVideoPlay(true));
    
    player.current?.play();
  }, [video.video_src])

  useEffect(() => {
    player.current?.pause();
    
    if (player.current) {
      player.current.muted = false;
    }

    video.play ? player.current?.play() : player.current?.pause();
  }, [video.play]);

  useEffect(() => {
    if (common.fullscreen) {
      player.current?.requestFullscreen();
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [common.fullscreen]);

  // progress bar 조정
  useEffect(() => {
    player.current && dispatch(setVideoCurrentTime(player.current.duration * video.change_range / 100));
    if (player.current?.currentTime) {
      player.current.currentTime = player.current.duration * video.change_range / 100;
    }
  }, [video.change_range]);

  const onTimeUpdate = () => {
    player.current && dispatch(setVideoCurrentTime(player.current.currentTime));

    // progress bar 
    player.current && dispatch(setVideoCurrentRange((100 * player.current.currentTime) / player.current.duration));
  };

  return (
    <VideoTag 
      ref={player}
      onTimeUpdate={() => {
        onTimeUpdate();
      }}
      onLoadedMetadata={() => {
        dispatch(setVideoCurrentTime(player.current?.currentTime));
        dispatch(setVideoDurationTime(player.current?.duration))
        dispatch(setVideoPlay(true));
      }}
    >
      <source src={ContentBookPath + book.current_book + Mp4Path + video.video_src + '.mp4'} type="video/mp4" />
    </VideoTag>
  );
};