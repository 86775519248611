import { createSlice } from "@reduxjs/toolkit";

interface CardSliceProps {
  unit : number,
  slide : {
    act? : boolean,
    choice? : boolean,
    word : string[][],
    back : boolean,
    reset : boolean,
    src : string,
    wsrc : string,
  }[][],
  word_play : boolean,
};

const initialState : CardSliceProps = {
  unit : 0,
  slide : [],
  word_play : false,
};

const card = createSlice({
  name : "card",
  initialState,
  reducers : {
    resetCard : () => initialState,
    setCardUnit(state, action) {
      state.unit = action.payload;
    },
    setSlide(state, action) {
      state.slide = action.payload;
    },
    setWordPlay(state, action) {
      state.word_play = action.payload;
    }
  }
});

export const { resetCard, setCardUnit, setSlide, setWordPlay } = card.actions;
 
export default card;