import { MainTemplate } from "../../_templates/MainTemplate/mainTemplate";

const Menu = [
  {
    src : 'https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/main_book1.png', 
    alt : 'Phonics Hero Book1'
  },
  {
    src : 'https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/main_book2.png', 
    alt : 'Phonics Hero Book2'
  },
  {
    src : 'https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/main/main_book3.png', 
    alt : 'Phonics Hero Book3'
  }
]

export const Main = () => {
  return (
    <MainTemplate menu={Menu}/>
  )
};