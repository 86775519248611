import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store";
import { setMemo, setMemoAct } from "../../../store/ToolSlice";
import { Text } from "../../_atoms/Text/text";
import { ImageButton } from "../ImageButton/imageButton";

export interface MemoProps {
  
};

const MemoWrap = styled.div`
  background: center /contain no-repeat;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s;
  z-index: 4;
  width: 17.083333vw;
  height: 18.125vw;
  background-image: url(https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/memo/memo.png);

  &:active {
    cursor: move;
  }
`;

export const Memo = ({...props}: MemoProps) => {
  const tool = useSelector((state : RootState) => state.tool);
  const dispatch = useDispatch();

  const onMemoText = (e : React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setMemo(e.target.value));
  };

  return (
    <Draggable bounds="parent">
      <MemoWrap>
        <Text 
          kind="memo" 
          tabIndex={100} 
          maxLength={1000}
          onChange={(e) => onMemoText(e)}
        >
          {tool.memo}
        </Text>
        <ImageButton
          kind="memoClose" 
          src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/memo/memo_btn_close.png" 
          alt="�޸� �ݱ�"
          onClick={() => dispatch(setMemoAct(false))}
        />
      </MemoWrap>
    </Draggable>
  )
};