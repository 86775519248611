import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Readers } from '../../../asset/utils/Readers';
import { StudentBook } from '../../../asset/utils/StudentBook';
import { WorkBook } from '../../../asset/utils/WorkBook';
import { RootState } from "../../../store";
import { setBookInfo } from '../../../store/BookSlice';
import { Frame } from "../../_molecules/Frame/frame";
import { Mask } from '../../_molecules/Mask/mask';
import { Memo } from '../../_molecules/Memo/memo';
import { AudioListWrap } from '../../_organisms/AudioListWrap/audioListWrap';
import { AudioPlayerWrap } from '../../_organisms/AudioPlayerWrap/audioPlayerWrap';
import { EbookMenuWrap } from "../../_organisms/EbookMenuWrap/ebookMenuWrap";
import { EbookWrap } from '../../_organisms/EbookWrap/ebookWrap';
import { FlashCardWrap } from '../../_organisms/FlashCardWrap/flashCardWrap';
import { GameWrap } from '../../_organisms/GameWrap/gameWrap';
import { PopupWrap } from '../../_organisms/PopupWrap/popupWrap';
import { VideoWrap } from '../../_organisms/VideoWrap/videoWrap';

export interface EbookTemplateProps {

};

export const EbookTemplate = ({ ...props }: EbookTemplateProps) => {
  const book = useSelector((state: RootState) => state.book);
  const common = useSelector((state: RootState) => state.common);
  const tool = useSelector((state: RootState) => state.tool);
  const dispatch = useDispatch();

  useEffect(() => {
    // BookList
    if (book.current_menu === 0 || book.current_menu === 2) {
      // StudentBook
      if (book.current_content === 0) {
        dispatch(setBookInfo(StudentBook[book.current_book - 1]));
        // WorkBook
      } else if (book.current_content === 1) {
        dispatch(setBookInfo(WorkBook[book.current_book - 1]));
        // Readers
      } else if (book.current_content === 2) {
        dispatch(setBookInfo(Readers[book.current_book - 1]));
      }
    }
  }, [book.current_book, book.current_menu, book.current_content, book.current_unit, book.current_page]);

  return (
    <>
      {/* Audio Track */}
      {common.pop_audio && (
        <PopupWrap kind="audio">
          <AudioListWrap page={false} />
          <AudioPlayerWrap page={false} />
        </PopupWrap>
      )}
      {/* animation */}
      {common.pop_video && (
        <PopupWrap kind="video">
          <VideoWrap />
        </PopupWrap>
      )}

      {/* flashcard */}
      {common.pop_card && (
        <PopupWrap kind="card">
          <FlashCardWrap />
        </PopupWrap>
      )}

      {/* game */}
      {common.pop_game && (
        <PopupWrap kind="game">
          <GameWrap />
        </PopupWrap>
      )}

      <Frame kind="ebook">
        <EbookWrap/>
        {/* memo */}
        {tool.memo_act && (
          <Memo />
        )}

        {/* mask */}
        {tool.mask_act && (
          <Mask />
        )}

        <EbookMenuWrap />
      </Frame>
    </>
  );
};
