import { createSlice } from "@reduxjs/toolkit";

interface MenuSliceProps {
  act : boolean,
  content_menu : boolean,
  unit_menu : boolean,
  animation_menu : boolean,
  menu : number | null,
  content : number | null,
  unit : number | null,
  animation : number | null,
  bookmark : string | null
};

const initialState : MenuSliceProps = {
  act : true,
  content_menu : false,
  unit_menu : false,
  animation_menu : false,
  menu : null,
  content : null,
  unit : null,
  animation : null,
  bookmark : null
};

const menu = createSlice({
  name : 'menu',
  initialState,
  reducers : {
    closeMenu(state) {
      state.content_menu = false;
      state.unit_menu = false;
      state.animation_menu = false;
      state.menu = null;
      state.content = null;
      state.unit = null;
      state.animation = null;
      state.bookmark = null;
    },
    setAct(state, action) {
      state.act = action.payload;
    },
    setContentMenu(state, action) {
      state.content_menu = action.payload;
    },
    setUnitMenu(state, action) {
      state.unit_menu = action.payload;
    },
    setAnimationMenu(state, action) {
      state.animation_menu = action.payload;
    },
    setMenu(state, action) {
      state.menu = action.payload;
    },
    setContent(state, action) {
      state.content = action.payload;
    },
    setUnit(state, action) {
      state.unit = action.payload;
    },
    setAnimation(state, action) {
      state.animation = action.payload;
    },
    setBookmark(state, action) {
      state.bookmark = action.payload;
    }
  }
});

export const {closeMenu, setAct, setContentMenu, setUnitMenu, setAnimationMenu, setMenu, setContent, setUnit, setAnimation, setBookmark} = menu.actions;

export default menu;