import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Readers } from '../../../asset/utils/Readers';
import { SlideMenuList } from '../../../asset/utils/SlideMenuList';
import { StudentBook } from '../../../asset/utils/StudentBook';
import { WorkBook } from '../../../asset/utils/WorkBook';
import { RootState } from '../../../store';
import { resetAudio } from '../../../store/AudioSlice';
import { setCurrentAnimation, setCurrentContent, setCurrentMenu, setCurrentPage, setCurrentUnit } from '../../../store/BookSlice';
import { resetCard, setCardUnit } from '../../../store/CardSlice';
import { closePop, setPopAudio, setPopCard, setPopGame, setPopVideo } from '../../../store/CommonSlice';
import { resetGame } from '../../../store/GameSlice';
import { setAnimation, setAnimationMenu, setBookmark, setContent, setContentMenu, setMenu, setUnit } from '../../../store/MenuSlice';
import { resetCurrentVideo, resetVideo, setVideoSrc } from '../../../store/VideoSlice';
import { Anchor } from "../../_atoms/Anchor/anchor";
import { Heading } from "../../_atoms/Heading/heading";
import { Item } from "../../_atoms/Item/item";
import { List } from "../../_atoms/List/list";

export interface EbookMenuListProps {
  list : {
    depth1 : string,
    depth2 : string[],
    depth3? : (string[] | null)[],
    depth4? : string[][],
  },
  depth1 : number,
};

export const EbookMenuList = ({
  list = {
    depth1 : 'Book List',
    depth2 : ['Student Book', 'Work Book', 'Readers'],
    depth3 : [['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3', 'unit 4', 'unit 5', 'unit 6', 'unit 7', 'unit 8', 'unit 9', 'unit 10', 'unit 11', 'unit 12'], ['unit 1', 'unit 2', 'unit 3']]
  },
  ...props
}: EbookMenuListProps) => {
  const common = useSelector((state : RootState) => state.common);
  const menu = useSelector((state : RootState) => state.menu);
  const book = useSelector((state : RootState) => state.book);
  const dispatch = useDispatch();
  
  const [top, setTop] = useState(0);

  // 콘텐츠 메뉴 
  const onDepth2Menu = (depth1 : number, depth2 : number) => {
    dispatch(closePop());
    dispatch(resetAudio());
    dispatch(resetVideo());
    dispatch(resetCard());
    dispatch(resetGame());

    // 메뉴 토글
    if (menu.menu === depth1 && menu.content === depth2) {
      dispatch(setContentMenu(!menu.content_menu));
    } else {
      dispatch(setContentMenu(true));
      dispatch(setMenu(depth1));
      dispatch(setContent(depth2));
      dispatch(setCurrentMenu(depth1));
      dispatch(setCurrentContent(depth2));
    }

    // Resource
    if (depth1 === 1) {
      // Audio Track
      if (depth2 === 0) {
        dispatch(setPopAudio(!common.pop_audio));
      // game
      } else if (depth2 === 3) {
        dispatch(setPopGame(!common.pop_game));
      }
    }

    dispatch(setUnit(null));
    dispatch(setAnimationMenu(false));
  };

  // 유닛 메뉴
  const onDepth3Menu = (e : React.MouseEvent, depth3 : number) => {
    dispatch(setUnit(depth3));

    if (menu.menu === 1 && menu.content === 1) {
      const parentLi = e.currentTarget.parentNode;
      if (typeof parentLi === 'object') {
        setTop(Math.abs((parentLi?.parentNode as HTMLElement).scrollTop - (parentLi as HTMLElement).offsetTop));
      }

      dispatch(setAnimationMenu(true));
      dispatch(setAnimation(null));
    } else {
      dispatch(setAnimationMenu(false));
    }
    
    // BookList
    if (menu.menu === 0) {
      // StudentBook
      if (menu.content === 0) {
        dispatch(setCurrentPage(StudentBook[book.current_book - 1].unit[depth3]));
      // WorkBook
      } else if (menu.content === 1) {
        dispatch(setCurrentPage(WorkBook[book.current_book - 1].unit[depth3]));
      // Readers
      } else if (menu.content === 2) {
        dispatch(setCurrentPage(Readers[book.current_book - 1].unit[depth3]));
      }
    // Resource
    } else if (menu.menu === 1) {
      // flashCard 
      if (menu.content === 2) {
        dispatch(resetCard());
        dispatch(setPopCard(true));
        dispatch(setCardUnit(depth3));
      } 
    }

    dispatch(setContentMenu(true));
    dispatch(setCurrentMenu(menu.menu));
    dispatch(setCurrentContent(menu.content));
    dispatch(setCurrentUnit(depth3));
  };

  // 애니메이션 메뉴
  const onDepth4Menu = (depth4 : number) => {
    dispatch(resetCurrentVideo());
    dispatch(setAnimation(depth4));
    dispatch(setCurrentAnimation(depth4));
    dispatch(setPopVideo(true));
    dispatch(resetAudio());
    dispatch(resetCard());
    
    const depth3 = SlideMenuList[book.current_book - 1][1].depth3;
    depth3["1"] && dispatch(setVideoSrc(book.current_unit === depth3["1"].length - 1 ? (book.current_book) + '_R_' + (depth4 + 1) : (book.current_book) +'_'+ (book.current_unit + 1) + '_' + (depth4 + 1)));
  };

  // 북마크 메뉴
  const onBookmarkMenu = (e : React.MouseEvent, depth3 : string) => {
    dispatch(setAnimationMenu(false));
  
    dispatch(setUnit(null));
    dispatch(setCurrentMenu(props.depth1));
    dispatch(setCurrentContent(menu.content));
    dispatch(setCurrentUnit(null));
    dispatch(setBookmark(depth3));
    dispatch(setCurrentPage(depth3));
  };

  return (
    <List order={false} kind="aside">
      <Heading level={1} kind="aside">{list.depth1}</Heading>
      {list.depth2.map((depth2, i) => {
        return (
          <Item kind="aside">
            <Anchor
              kind="aside"
              act={menu.content_menu && menu.menu === props.depth1 && menu.content === i ? true : false}
              onClick={() => onDepth2Menu(props.depth1, i)}
            >
              {depth2}
            </Anchor>
            {list && 'depth3' in list && (
              list.depth3 && list.depth3[i] && (
                <List order={false} kind="asideIn" act={menu.content_menu && menu.menu === props.depth1 && menu.content === i ? true : false}>
                {props.depth1 === 2 ? 
                  book.bookmark && book.bookmark.filter((bookmark) => bookmark.book === book.current_book && bookmark.content === i).map((bookmark, j) => {
                    return (
                      <Item kind="asideIn">
                        <Anchor
                          kind="asideIn"
                          act={menu.content_menu && props.depth1 === menu.menu && i === menu.content && bookmark.page === menu.bookmark ? true : false}
                          onClick={(e : React.MouseEvent) => onBookmarkMenu(e, bookmark.page)}
                        >
                          {i === 0 ? 'StudentBook_' : (i === 1 ? 'WorkBook_' : 'Readers_')}{bookmark.page}
                        </Anchor>
                      </Item>
                    )
                  })
                  :
                  list.depth3 && list.depth3[i]?.map((depth3, j) => {
                    return (
                      <Item kind="asideIn">
                        <Anchor
                          kind="asideIn"
                          act={menu.content === i && menu.unit === j ? true : false}
                          onClick={(e : React.MouseEvent) => onDepth3Menu(e, j)}
                        >
                          {depth3}
                        </Anchor>
                      </Item>
                    )
                  })
                }
                </List>
              )
            )}
            {props.depth1 === 1 && i === 1 && (
              <List order={false} kind="animation" top={top} act={menu.animation_menu ? true : false}>
                {list.depth4 && menu.unit !== null && list.depth4[menu.unit]?.map((depth4, k) => {
                  return (
                    <Item kind="animation">
                      <Anchor
                        kind="animation"
                        act={menu.animation_menu && menu.animation === k ? true : false}
                        onClick={() => onDepth4Menu(k)}
                      >
                        {depth4}
                      </Anchor>
                    </Item>
                  )
                })}
              </List>
            )}
          </Item>
        )
      })}
    </List>
  )
};