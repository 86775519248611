import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { RootState } from '../../../store';
import { resetAudio } from '../../../store/AudioSlice';
import { resetBook, setBookmark } from '../../../store/BookSlice';
import { resetCard } from '../../../store/CardSlice';
import { closePop, setBtnSound } from '../../../store/CommonSlice';
import { resetGame } from '../../../store/GameSlice';
import { closeMenu } from '../../../store/MenuSlice';
import { resetVideo } from '../../../store/VideoSlice';
import { Button } from "../../_atoms/Button/button";
import { Canvas } from '../../_atoms/Canvas/canvas';
import { Section } from "../../_atoms/Section/section";
import { Ebook } from '../../_molecules/Ebook/ebook';
import { EbookPage } from "../../_molecules/EbookPage/ebookPage";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";

export interface EbookWrapProps {

};

export const EbookWrap = ({ ...props }: EbookWrapProps) => {
  const menu = useSelector((state: RootState) => state.menu);
  const book = useSelector((state: RootState) => state.book);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBookMark = () => {
    if (book.bookmark.filter((bookmark) => bookmark.book === book.current_book && bookmark.content === book.current_content && bookmark.page === book.current_page).length > 0) {
      const newBookmark = book.bookmark.filter((bookmark) => bookmark.book !== book.current_book || bookmark.content !== book.current_content || bookmark.page !== book.current_page);
      dispatch(setBookmark(newBookmark));
    } else {
      const bookmark = {
        book: book.current_book,
        content: book.current_content,
        page: book.current_page
      };

      dispatch(setBookmark([...book.bookmark, bookmark]));
    }
  };

  const reset = () => {
    dispatch(closePop());
    dispatch(resetBook());
    dispatch(resetAudio());
    dispatch(resetVideo());
    dispatch(resetCard());
    dispatch(resetGame());
    dispatch(closeMenu());
  };

  return (
    <Section act={menu.act}>
      <Ebook />
      <Canvas />

      <Button
        kind="bookMark"
        act={book.bookmark.filter((bookmark) => bookmark.book === book.current_book && bookmark.content === book.current_content && bookmark.page === book.current_page).length > 0 ? true : false}
        onClick={() => { onBookMark(); }} />

      <ImageButton
        kind="home"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_home_150px.png"
        alt="HOME"
        onClick={() => {
          reset();
          dispatch(setBtnSound(true));
          navigate("/");
        }} />
      <ImageButton
        kind="back"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_pre_150px.png"
        alt="BACK"
        onClick={() => {
          reset();
          dispatch(setBtnSound(true));
          navigate("/menu");
        }} />

      <EbookPage />
    </Section>
  );
};
