import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import styled from "styled-components";
import { setBtnSound } from "../../../store/CommonSlice";
import { Button } from "../../_atoms/Button/button";
import { Frame } from "../../_molecules/Frame/frame";
import { ImageButton } from "../../_molecules/ImageButton/imageButton";

export interface MenuTemplateProps {
  
};

const BookNum = styled.div`
  position: absolute;
  top: 17.447917vw;
  left: 50%;
  transform: translateX(-50%);
  width: 5.729167vw;
  height: 5.729167vw;
  border-radius: 2.864583vw;
  border: .520833vw solid #fff;
  font-size: 3.645833vw;
  font-weight: 700;
  color: #fff;
  line-height: 5.208333vw;
  text-align: center;
  background-color: #fa776d;
`;

export const MenuTemplate = ({...props}: MenuTemplateProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Frame kind="menu">
      <BookNum>1</BookNum>
      <Button kind="menu" animal="rabbit" onClick={() => navigate("/audio")} onMouseOver={() => dispatch(setBtnSound(true))}/>
      <Button kind="menu" animal="fox" onClick={() => navigate("/ebook")} onMouseOver={() => dispatch(setBtnSound(true))}/>
      <Button kind="menu" animal="sheep" onClick={() => navigate("/resources")} onMouseOver={() => dispatch(setBtnSound(true))}/>
      <ImageButton
        kind="home"
        src="https://content-cdn.chungchy.com/PhonicsHero/web_resource/images/common/btn_home_150px.png"
        alt="home"
        onClick={() => {
          navigate("/");
          dispatch(setBtnSound(true));
        }}
      />
    </Frame>
  )
};