import styled, { css } from "styled-components";

export interface ParagraphProps {
  kind? : string,
  children? : React.ReactNode,
};

const PTag = styled.p<ParagraphProps>`
  ${(props) => props.kind === 'resource' && css`
    font-size: 1.5625vw;
  `}
`;

export const Paragraph = ({...props}: ParagraphProps) => {
  return (
    <PTag kind={props.kind}>
      {props.children}
    </PTag>
  )
};